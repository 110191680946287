import React from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

export default function UlkeRaporlari() {

  return (
    <div>
      <Helmet>
        <title>Ülke Raporları</title>
        <meta name="description" content="Ülke Raporları" />
        <meta name="keywords" content="Ülke Raporları" />
      </Helmet>


      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Ülke Raporları</span>
              </div>
              <div className="announcements">
                <div className="ulkeRaporu">
                  <a href='/Azerbaycan.pdf'>
                    <div class="card" style={{ width: '18rem' }}>
                      <img src="https://stratejikortak.com/wp-content/uploads/2016/09/azerbaycan-bayragi.png" class="card-img-top" alt="..." />
                      <div class="card-body">
                        <p class="card-text">Azerbaycan</p>
                      </div>
                    </div>

                  </a>


                  <a href='/Gurcistan.pdf'>
                    <div class="card" style={{ width: '18rem' }}>
                      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Georgia.svg/1200px-Flag_of_Georgia.svg.png" class="card-img-top" alt="..." />
                      <div class="card-body">
                        <p class="card-text">Gürcistan</p>
                      </div>
                    </div>

                  </a>
                </div>




              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
