import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function MuhasebeFinansBirimi() {
 
  return (
    <div className="container">
      <Helmet>
            <title>Muhasebe ve Finanas Birimi</title>
            <meta name="description" content="Muhasebe ve Finanas Birimi" />
            <meta name="keywords" content="Muhasebe ve Finanas Birimi" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Muhasebe Birimi Genel İşlemleri</span>
            </div>
            <div className="announcements">
              <p>Odanın bütçe ve muhasebe işlemlerini sosyal sorumluluk, kişilik, süreklilik, dönemsellik, parayla ölçülme, maliyet esası, tarafsızlık ve belgelendirme, tutarlılık, tam açıklama, özün önceliği, önemlilik ve ihtiyatlılık esaslarına göre yürütmek,

Bir yıla ait gelir ve gider tahminlerini gösteren Oda bütçesini hazırlayarak, genel sekretere sunmak,

Yıl içinde, tahmini bütçeyi aşan fasıl ve maddeler arasında aktarma teklifinde bulunmak, ek ve olağanüstü ödenek talep etmek,

Yönetmelikte belirtilen yevmiye, defter-i kebir, sabit kıymetler defteri, yardımcı defterlerleri onaylatmak, işlemek ve yıl sonunda ciltlettirilerek saklamak,

Mizan, gelir-gider bütçe izleme raporu, bilanço, kasa raporu ve gider pusulası düzenlemek,

Oda gelir ve giderlerini yılın ilk ayında tahakkuk ettirip tahsil ve ödemelerin bütçeye ve mevzuat hükümlerine göre uygun şekilde yapılmasını sağlamak,

Veznenin tahsil ettiği Oda gelir ve giderlerinin tahakkukunu yaparak, tahsil tediye ve mahsup fişlerini düzenlemek ve çıktısını saklamak

Her mali yılın ilk ayı içinde kasada bulundurulacak para miktarının belirlenmesini sağlamak, aşan miktarın bankalara yatırılmasını sağlamak

Banka mevduatları ile ilgili olarak çizelge tutmak,

Odanın yıllık genel ihtiyaçlarını tespit edip, satın alma programını genel sekreterliğe sunmak,

Oda demirbaşlarının kayıtlarını tutmak, süresini dolduran demirbaşların usulüne göre silinmesini sağlamak

Dönem başında harcırah çizelgesi hazırlayarak, Oda Meclisinin, Yönetim Kurulu Başkanının veya Genel Sekreterin şehir dışında görevlendirdiği kişilerin harcırahlarını düzenlemek, gerekirse görev tarihinden bir gün önce avans olarak ödenmesini sağlamak

Oda personelinin tayini, terfii, cezalandırılması, görevine son verilmesi ve sigorta ve emekli sandığı ile ilgili münasebetlerine ait her türlü işlemleri yapmak ve buna ilişkin dosyaları tutmak,

Oda personelinin ücret, fazla mesai ücreti, ikramiye, doğum, ölüm, evlenme yardımlarını tahakkuk ettirmek ve kayıtlarını tutmak,

Oda personelinin yıllık izinlerini, sağlık ve sosyal ihtiyaçlarını tutmak

Muhtasar beyannamesi , KDV beyannamesi, SSK İşsizlik sigortalarını, TOBB Emekli Sandığı primlerini, TOBB birlik paylarını hazırlamak ve ödemelerini yapmak 

</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
