import React,{useEffect} from 'react'

export default function KurumOrganlari() {
  useEffect(() => {
    document.title = "Kurum Organları";
  }, []);
  return (
    <div>kurumOrganlari</div>
  )
}
