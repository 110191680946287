import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export default function Haberler() {



  const [announcements, setAnnouncements] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const apiUrl = process.env.REACT_APP_API_STRING;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch(`${apiUrl}/getAnnouncement`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAnnouncements(data);
        } else {
          console.error('Duyurular getirilemedi.');
        }
      } catch (err) {
        console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
      }
    };

    const checkAdminStatus = () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAdmin(true);
      }
    };

    fetchAnnouncements();
    checkAdminStatus();
  }, [apiUrl]);

  const deleteAnnouncement = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/deleteAnnouncement`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ announcementid: id }),
      });

      if (response.ok) {
        setAnnouncements((prevAnnouncements) =>
          prevAnnouncements.filter(announcement => announcement._id !== id)
        );
        navigate('/');
      } else {
        console.error('Duyuru silinemedi.');
      }
    } catch (err) {
      console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
    }
  };
  return (
    <div className="container">
      <Helmet>
        <title>Haberler</title>
        <meta name="description" content="Haberler" />
        <meta name="keywords" content="Haberler" />
      </Helmet>
      <div className="row">
        <div className="col-lg-12" id='haberlerContainer'>
          {announcements.map((announcement, index) => (
            <div key={index} className="card" style={{ width: '18rem' }}>
              <Link style={{ textDecoration: 'none', color: 'black' }} to={`/announcementDetail/${announcement._id}`}>
                <img
                  src={`http://localhost:5050/uploads/${announcement.announcementImg}`}
                  className="card-img-top"
                  alt={announcement.announcement}
                />
                <div className="card-body">
                  <h5>{announcement.announcement}</h5>
                </div>
              </Link>
              {isAdmin && (
                <>
                  <div className="card-body">
                    <Link to={`/announcementEdit/${announcement._id}`} className="btn btn-primary">Düzenle</Link>
                  </div>

                  <div className="card-body">
                    <Link className="btn btn-danger" onClick={() => deleteAnnouncement(announcement._id)}>Sil</Link>
                  </div>

                </>

              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
