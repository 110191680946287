import React from 'react';
import {Helmet} from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function yonetimKuruluUyeleri() {
  return (
    <div>
      <Helmet>
            <title>Yönetim Kurulu Üyeleri</title>
            <meta name="description" content="Yönetim Kurulu Üyeleri" />
            <meta name="keywords" content="Yönetim Kurulu Üyeleri" />
        </Helmet>

        <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Yönetim Kurulu Üyeleri</span>
              </div>
              <div className="announcements">
                <div className="container">
                  <div className="row" style={{justifyContent:'center'}}>
                    <div className="col-sm-12 col-lg-12">
                      <div className="row" style={{justifyContent:'center'}}>
                        <div className="col-12" style={{display:'flex', justifyContent:'center'}}>


                          <div class="card" style={{ width: '18rem' }}>
                            <img src="/baskan.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                              <h5 class="card-title">Çetin DEMİRCİ</h5>
                              <span>Yönetim Kurulu Başkanı</span>
                            </div>
                          </div>


                        </div>

                        <div className="row cardContainer" >



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Metin LALE</h5>
                                <span>Yönetim Kurulu Başkan Yrd.</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Veyis OSMANOĞLU</h5>
                                <span>Yönetim Kurulu Sayman Üyesi</span>
                              </div>
                            </div>
                          </div>



                         
                        </div>


                        <div className="row cardContainer">



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Sebahattin GÜNDOĞDU</h5>
                                <span>Yönetim Kurulu Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Mahmut Ahmet VURAL</h5>
                                <span>Yönetim Kurulu Üyesi</span>
                              </div>
                            </div>
                          </div>



                        

                        </div>


                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
