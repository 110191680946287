import React,{useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function KurumsalLogo() {
  useEffect(() => {
    document.title = "Kurumsal Logo";
  }, []);
  return (
    <div className="container">
       <Helmet>
            <title>Kurumsal Logo</title>
            <meta name="description" content="Kurumsal Logo" />
            <meta name="keywords" content="Kurumsal Logo" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Kurumsal Logomuz</span>
          </div>
          <div className="announcements">
            <h5 className='mt-5'>DOĞU ANADOLUNUN ALTIN ŞEHRİ ARDAHAN</h5>
            <p>Logo iç içe geçmiş şekilde kurgulanarak stilize edilmiş 4 adet A bulunmaktadır. bu ‘’A ‘’ harfi Ardahan ilinin baş harfi olmakla beraber Ardahan ilinin 4 temel ticari dinamiğini temsil etmektedir. (Hayvancılık, Turizm, Sınır Ticareti, Doğal Tabiat Yaşamı)</p>
            <p>Logoda kullanılan altın rengi ise Ardahan ilinin sahip olduğu potansiyele atıfta bulunarak bulundurduğu zenginliği ifade etmektedir. </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
