import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function OdaSicilMemurlugu() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Oda Sicil Memurluğu</title>
            <meta name="description" content="Oda Sicil Memurluğu" />
            <meta name="keywords" content="Oda Sicil Memurluğu" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Oda Sicil Memurluğu Genel Hizmetleri</span>
          </div>
          <div className="announcements">
            <p>Yeni üye kayıt başvurularını sonuçlandırmak. Yönetim Kurulu kabul kararı tarihi itibariyle Odaya kaydını yapmak, sicil dosyasını tutmak ve muhafaza etmek.</p>
            <p>Üyeliğe kaydolunan Meslek grupları ve aidat derecelerinin tespiti, değiştirilmesi ve bu husustaki kararların ilgililere tebliği, yapılan itirazların ait oldukları merciye intikal ettirilmesini sağlamak.</p>
            <p>Odaya kaydı gerektiği halde başvurmayanları izlemek ve kayıt yaptırılmasını sağlamak.</p>
            <p>Kayıtlı üyelerin durumundaki değişiklikleri izlemek ve gerekli tadilleri yapmak.</p>
            <p>Üyelerin belge taleplerini karşılamak ve dosyalarından talep edilen suretleri vermek.</p>
            <p>Oda üyelerinin kimlik taleplerini karşılamak.</p>
            <p>Üyelerin ve diğer kuruluşların sicil kayıtlarına ilişkin yazılı ve sözlü sorularını yanıtlamak.</p>
            <p>Üyelerin diğer kamu kurumları nezdindeki işlemleri için gerekli belgeleri düzenlemek.</p>
            <p>Oda üyelik kayıtlarının terkinini yapmak.</p>
            <p>Oda organlarının 5174 Sayılı Odalar ve Borsalar Birliği Kanun ve tüzüğüne göre seçimlerinin yapılmasını sağlamak. </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
