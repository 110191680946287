import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'

export default function EgitimOrganizasyonBirimi() {
 
  return (
    <div className="container">
      <Helmet>
            <title>Eğitim ve Organizasyon Birimi</title>
            <meta name="description" content="Eğitim ve Organizasyon Birimi" />
            <meta name="keywords" content="Eğitim ve Organizasyon Birimi" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Eğitim ve Organizasyon</span>
            </div>
            <div className="announcements">
              <p>Oda içerisinde ve Oda dışında yapılacak, katılım sağlanacak olan toplantı, seminer, konferans, sempozyum, panel, çalıştay, kongre, eğitim, sosyal etkinlik, gezi, tören, basın toplantısı, ziyaret ve yurtiçi fuar organizasyonlarının takibini yürütür. Yurtiçi fuarlar ile ilgili duyuru ve stant açma gibi iş ve işlemleri yürütür.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
