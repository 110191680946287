import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Helmet} from 'react-helmet'
export default function Duyurular() {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const apiUrl = process.env.REACT_APP_API_STRING;
  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await fetch(`${apiUrl}/announcementDetail/${id}`);
        if (response.ok) {
          const data = await response.json();
          setAnnouncement(data);
        } else {
          console.error('Duyuru getirilemedi.');
        }
      } catch (err) {
        console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
      }
    };

    fetchAnnouncement();
  }, [id,apiUrl]);

 
  return (
    <div className="container">
      <Helmet>
            <title>Duyurular</title>
            <meta name="description" content="Duyurular" />
            <meta name="keywords" content="Duyurular" />
        </Helmet>
      <div className="row" id='announcementRow'>
        <div className="col-sm-12 col-lg-8">
          {announcement ? (
            <div className='announcementContainer'>
              <img src={`http://localhost:5050/uploads/${announcement.announcementImg}`} alt={announcement.announcement} />
              <h2 className='mt-5 text-center'>{announcement.announcement}</h2>
              <p className='mt-3 text-center'>{announcement.announcementDetail}</p> 
              
              <h2 className='mt-3 text-center' >{announcement.announcement2}</h2>
              <p className='mt-3 text-center'>{announcement.announcementDetail2}</p> 

              <h2 className='mt-5 text-center'>{announcement.announcement3}</h2>
              <p className='mt-3 text-center'>{announcement.announcementDetail3}</p> 
              


              {announcement.announcementFile && (
                 <div className="sigorta">
                 <a className='mevzuat' href={`${apiUrl}/uploads/${announcement.announcementFile}`} download>
                   Dosyayı İndir
                 </a>
             </div>
              )}

             
            </div>
          ) : (
            <p>Duyuru yükleniyor...</p>
          )}
        </div>
      </div>
    </div>
  );
}
