import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
export default function AbKaliteAkreditasyon() {
 
  return (
    <div className="container">
      <Helmet>
            <title>AB Kalite ve Akreditasyon</title>
            <meta name="description" content="AB Kalite ve Akreditasyon" />
            <meta name="keywords" content="AB Kalite ve Akreditasyon" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>AB Kalite ve Akreditasyon Birimi Genel Hizmetleri</span>
            </div>
            <div className="announcements">
              <p>AB kaynaklı fonlardan yararlanarak ilimiz faydasına olacak Projeler hazırlamak ve uygulamak.</p>
              <p>Üyelerimizi ve özellikle sanayicilerimizin Avrupa Birliğine uyum sürecinde karşılaştıkları problemlerin çözümü yönünde yol göstermek,
                Üyelerimizin; özellikle standartlar, krediler, haksız rekabet gibi konularda karşılaştıkları sorunlarda yardımcı olmak,
                Avrupa Birliği ve KOBİ’lerin kapsamına giren her türlü konuyla ilgili toplantılara katılmak ve bunlarla ilgili raporları hazırlamak, İlgili makamlardan talep edildiği takdirde standartlar, CE İşareti, KOBİ kredileri, Rekabet ve Sektörel bazdaki diğer konularla ilgili araştırmalar yapıp, bunları rapor halinde yetkili mercilere sunmak,</p>
              <p>Avrupa Birliği ve Dünya Bankası kaynaklı fonlardan üyelerimizin istifadesi yönünde çalışmalarda bulunmak,
                AB Çerçeve Programları hakkında araştırmalar yapmak ve üyelerimizi bilgilendirmek,
                Çeşitli konularda ekonomik analizler ve araştırmalar yapmak, sonuçları Genel Sekreterliğe, Yönetim Kurulu’na sunmak ve ilgili üyelere duyurmak,
                Periyodik olarak çeşitli ekonomik verileri takip etmek.</p>
              <p>Odanın çeşitli konularda düzenlediği anketleri hazırlamak ve sonuçlarını Yönetim Kurulu’na sunmak. </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
