import React from 'react';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function hayvansalUrunler() {
  return (
    <div>
      <Helmet>
        <title>Hayvansal Ürünler</title>
        <meta name="description" content="Hayvansal Ürünler" />
        <meta name="keywords" content="Hayvansal Ürünler" />
      </Helmet>


      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Hayvansal Ürünler</span>
              </div>
              <div className="announcements">
                <strong>HAYVANCILIKTA</strong>
                <p>Temel geçim kaynaklarımızdan biri olan hayvancılıktan  elde ettiğimiz süt ürünlerimiz…</p>
                <ol>
                  <li>Peynir`deri peyniri,  lor peyniri, yağlı peynir, çeçil peyniri</li>
                  <li>Yağ sarı yağ( çoma yağı)</li>
                  <li>Tere yağı</li>
                </ol>
                <p>
                  Hayvancılıktan elde edilen ürünlerimiz  yılın dört mevsimin de özellikle kış mevsimin de kullanılmak üzere üretilir ihtiyaç fazlası satılarak aile bütçesine katkıda bulunulur…
                </p>
                <strong>ARICILIK:</strong>
                <p>
                  Doğal şartlarda yapılan arıcılık yaygın olmamasına rağmen  son zamanlarda arıcılıkta ciddi artış görülmekte olup arıcılığın köyümüzde daha fazla yapılması  gerekmektedir…
                </p>
                <strong>KAZ:</strong>
                <p>
                  Bölgemizin ve köyümüzün en gözde et ürünlerinden birisi olan  kaz üretiminde düşüş hiç görülmediği  gibi tüketimi  köy ve bölge  dışında yaşayan kişiler tarafından da ciddi şekilde ilgi gördüğü  için en değerli  besin  ürünlerimizden birisidir …
                </p>
                <strong>TAVUK, ÖRDEK:</strong>
                <p>
                  Et ve  yumurtasından yaralanmak için hemen hemen  her aile tarafından üretimi yapılmaktadır…
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
