import React from 'react';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

export default function dogalUrunler() {
  return (
    <div>
      <Helmet>
        <title>Doğal Ürünler</title>
        <meta name="description" content="Doğal Ürünler" />
        <meta name="keywords" content="Doğal Ürünler" />
      </Helmet>


      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Doğal Ürünler</span>
              </div>
              <div className="announcements">
                <strong>DOĞAL ÜRÜNLERİMİZ</strong>
                <p>
                  Dünyanın lezzetli su  ürünlerin den biri olan  alabalık  köyümüzden geçen cot  çayında yetişmektedir , alabalık , kara balık , mürze balığı  avcılığı doğal yollardan avlanarak besin ürünlerimiz arasındadır sudan çıkartılan bu balıklarımızın en ilginç avlanma yöntemi  kaya altından el ile tutulmasıdır. Dünyada nadir görünen bu avlanma yöntemi çok zevkli ve heyecanlı olduğu bilinmektedir..
                </p>
                <p>
                  Doğadan doğal ürünler:  böğürtlen, horoz gözü,  adıl , yemlik, kuzu kulak,   kuş ekmeği , acıgıcı, kımı , cacık, ısırgan   yazamadığımız ve keşfedilmemiş  binlerce doğal ürünlerimiz mevcuttur
                </p>
                <strong>Not:</strong>
                <span>Araştırmalar sonucu en doğal bitki ürünleri ve en faydalı bitki ürünleri  Ardahan ve   civarında bulunduğu söylenmektedir…</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
