import React, { useState, useEffect } from 'react';

export default function GelenIletisimFormlari() {
    const [contacts, setContacts] = useState([]);
    const apiUrl = process.env.REACT_APP_API_STRING;

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await fetch(`${apiUrl}/sendContactInfo`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setContacts(data);
                } else {
                    console.error('İletişim Formları getirilemedi.');
                }
            } catch (err) {
                console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
            }
        };

        fetchContacts();
    }, [apiUrl]);

    return (
        <div className='container'>
            <h2>Gelen İletişim Formları</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Sıra</th>
                        <th>Ad</th>
                        <th>Soyad</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Açıklama</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map((contact, index) => (
                        <tr key={contact._id}>
                            <td>{index + 1}</td>
                            <td>{contact.name}</td>
                            <td>{contact.surname}</td>
                            <td>{contact.phone}</td>
                            <td>{contact.email}</td>
                            <td>{contact.aciklama}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
