import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function KBelgeYetkisi() {
  
  return (
    <div className="container">
      <Helmet>
            <title>K Belge Yetkisi</title>
            <meta name="description" content="K Belge Yetkisi" />
            <meta name="keywords" content="K Belge Yetkisi" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>"K" Türü Yetki Belgeleri Birimi Genel İşlemleri</span>
          </div>
          <div className="announcements">
            <p>Kurumumuz bünyesinde hizmet veren "Ardahan İl K Türü Yetki Belgesi Bürosu" aşağıda belirtilen 5 çeşit yetki belgesinin düzenleme ve tescil hizmetlerini vermektedir.</p>
            <strong>K1 Yetki Belgesi:</strong>
            <p>Belirli bir zaman tarifesine göre veya belirli bir zaman tarifesine uymaksızın eşya taşımacılığı yapacaklara K1 türü Yetki Belgesi verilir.</p>
            <strong>K2 Yetki Belgesi:</strong>
            <p>Taşımacının kendi adına ticari olarak kayıt ve tescil edilmiş taşıt veya taşıtlarla, sadece kendi esas iştigal konusu ile ilgili eşya taşımacılığı yapacak ve ticari maksatla taşımacılık yapmayacaklara K2 türü Yetki Belgesi verilir.</p>
            <strong>K3 Yetki Belgesi:</strong>
            <p>Ev ve büro eşyası taşımacılığı yapacaklara K3 türü Yetki Belgesi verilir.</p>
            <strong>K1* Yetki Belgesi:</strong>
            <p>Yalnızca il sınırları içerisinde taşıma yapan, ruhsatları ticari olan ve başkalarının yükünü taşıyan nakliyecilik sektöründe olanlara K1* türü Yetki Belgesi verilir.</p>
            <strong>K2* Yetki Belgesi:</strong>
            <p>Yurt içinde taşıma yapacak olan, ruhsatlarında hususi veya ticari olan, sadece kendi işleri ile ilgili yüklerini taşıyanlar K2* türü Yetki Belgesi verilir.</p>

            <h5>K Türü Yetki Belgeleri</h5>
            <div className="sigorta">
              <FontAwesomeIcon icon={faFilePdf} />
              <a className='mevzuat' href="/K1_YetkiBelgesi.pdf">K1 Yetki Belgesi</a>
            </div>

            <div className="sigorta">
              <FontAwesomeIcon icon={faFilePdf} />
              <a className='mevzuat' href="/K2_YetkiBelgesi.pdf">K2 Yetki Belgesi</a>
            </div>

            <div className="sigorta">
              <FontAwesomeIcon icon={faFilePdf} />
              <a className='mevzuat' href="/K3_YetkiBelgesi.pdf">K3 Yetki Belgesi</a>
            </div>

            <h5>Karayolları Taşıma Yönetmeliği</h5>
            <div className="sigorta">
              <FontAwesomeIcon icon={faFilePdf} />
              <a className='mevzuat' href="/K1_YetkiBelgesi.pdf">K1 Yetki Belgesi</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
