import React from 'react'
import { Helmet } from 'react-helmet'
export default function ResimGalerisi() {
 
  return (
    <div>
      <Helmet>
            <title>Resim Galerisi</title>
            <meta name="description" content="Resim Galerisi" />
            <meta name="keywords" content="Resim Galerisi" />
        </Helmet>
      resimGalerisi

    </div>
  )
}
