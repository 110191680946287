import React from 'react'
import {Helmet} from 'react-helmet'
export default function DeikBirimi() {
  
  return (
    <div>
      <Helmet>
            <title>DEİK Birimi</title>
            <meta name="description" content="DEİK Birimi" />
            <meta name="keywords" content="DEİK Birimi" />
        </Helmet>
      deikBirimi

    </div>
  )
}
