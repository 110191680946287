import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons'
import {Helmet} from 'react-helmet'
export default function SigortaKayıtBirimi() {
 
  return (
    <div className="container">
      <Helmet>
            <title>Sigorta Kayıt Birimi</title>
            <meta name="description" content="Sigorta Kayıt Birimi" />
            <meta name="keywords" content="Sigorta Kayıt Birimi" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Sigortacılık Levha Kayıt İşlemleri</span>
          </div>
          <div className="announcements">
            <p>5684 sayılı Sigortacılık Kanunu gereğince düzenlenecek Sigorta Eksperleri Ruhsatı ile Sigorta Acenteleri Uygunluk Belgesi başvurularının kabulüne ilişkin olarak Ardahan Ticaret ve Sanayi Odası, Türkiye Odalar ve Borsalar Birliği ve T.C. Başbakanlık Hazine Müsteşarlığı arasında yapılan protokol neticesinde yetkilendirilmiştir.Odamız bu çerçevede Ardahan merkezi bulunan Sigorta Acentelerinin taleplerini karşılamak amacıyla Sigortacılık Birimi'ni oluşturmuştur. Sigortacılık Birimimize 0478 211 29 70 nolu telefondan, ulaşıp bilgi alabilirsiniz. </p>
            <h5>İlgili Mevzuat ve Kanunlar</h5>
            <div className="sigorta">
              <FontAwesomeIcon icon={faFilePdf} />
              <a className='mevzuat' href="/sigortacılıkMevzuat.pdf">Sigortacılık Kanunu</a>
            </div>
            
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
