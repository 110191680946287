import React from 'react'
import { Helmet } from 'react-helmet'
export default function Urunler() {
  
  return (
    <div>
      <Helmet>
            <title>Ürünler</title>
            <meta name="description" content="Ürünler" />
            <meta name="keywords" content="Ürünler" />
        </Helmet>
      urunler

    </div>
  )
}
