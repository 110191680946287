import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function KullanimSartlari() {
  return (
    <div className='container'>
      <Helmet>
        <title>Kullanım Şartları</title>
        <meta name="description" content="Kullanım Şartları" />
        <meta name="keywords" content="Kullanım Şartları" />
      </Helmet>
      <div className="row">
        <div className="col-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Kullanım Şartları</span>
            </div>
            <div className="announcements">
              <b>KULLANICI ile  Ardahan Ticaret ve Sanayi Odası ARASINDAKİ ANLAŞMA</b>
              <p>Ardahan Ticaret ve Sanayi Odası Web Sitesi çeşitli sayfalardan oluşur ve yönetimi Ardahan Ticaret ve Sanayi Odası sitesi yöneticileri tarafından yapılmaktadır.</p>
              <p>Aşağıdaki metinde yazılı bulunan ve Ardahan Ticaret ve Sanayi Odası ile ilgili Kullanım Şartları, Gizlilik ve Telif Hakları ile ilgili size bilgi veren metni okumanız gerekmektedir. Bu anlaşmayı kabul ettiğiniz takdirde, üye veya ziyaretçi olarak Ardahan Ticaret ve Sanayi Odası sitemizi kullandığınızda şartlara uymanız beklenecektir. Sitemizden faydalanmaya başlamadan önce sizden ne gibi bilgiler istendiğini ve bunların ne amaçla istendiğini anladığınızdan emin olanız sizin yararınızadır.</p>
              <b>KULLANIM ŞARTLARI İLE İLGİLİ</b>
              <p>Ardahan Ticaret ve Sanayi Odası Kullanım Şartları, Gizlilik ve Telif Hakları gibi konularda değişiklik yapma hakkını saklı tutar.</p>
              <b>ÜÇÜNCÜ PARTİ SİTELER HAKKINDA</b>
              <p>Ardahan Ticaret ve Sanayi Odası Web Sitesi diğer Web Sitelerine bağlantılar (veya yönlendirmeler) içerebilir. Bağlantısı verilen siteler Ardahan Ticaret ve Sanayi Odası tarafından yönetilmediği için Ardahan Ticaret ve Sanayi Odası bağlantısı verilen linklerin içeriği konusunda herhangi bir sorumluluk almaz (site içeriği daha sonra değişse bile bu durum geçerlidir). Ardahan Ticaret ve Sanayi Odası bağlantısı verilen sitelerle ilgili webcasting veya diğer türlü transmisyon durumlarından sorumlu değildir. Ardahan Ticaret ve Sanayi Odası bu bağlantıları sadece uygun gördüğü için yayınlamaktadır. Ancak bu bağlantılardaki içeriğe yapılan ilaveler Ardahan Ticaret ve Sanayi Odası veya  ortakları tarafından kontrol edilmemektedir.</p>
              <b>YASALARA AYKIRI KULLANIMLA İLGİLİ</b>
              <p> Ardahan Ticaret ve Sanayi Odası Web Sitesini kullanmaya başladığınız andan itibaren , ulusal ve uluslararası yasalara aykırı davranışlarda bulunmayacağınızı ve Ardahan Ticaret ve Sanayi Odası tarafından ilan edilen kurallara uyacağınızı garanti etmiş olursunuz. Ardahan Ticaret ve Sanayi Odası Web Sitesine ve sahiplerine zarar verecek hiçbir davranışta bulunmamalısınız. Ardahan Ticaret ve Sanayi Odası tarafından sağlanan materyallerin veya içeriğin gerekli izinler alınmadan üçüncü parti sitelerde veya kurumlarda bir kısmının veya tamamının yayınlanması/kullanılması  yasaktır.</p>
              <b>SERVİSLERİN KULLANIMI HAKKINDA</b>
              <p>Ardahan Ticaret ve Sanayi Odası Web Sitesi forum, chat, haber grupları, kişisel web sayfaları, takvimler ve/veya diğer mesajlaşma ya da kominükasyon kolaylıklarını sunabilir. Bu tür servisler hizmete sunulduğunda Servislerle ilgili kullanım koşullarını dikkate almalısınız. Kominükasyon Servislerini kullanmaya başlamanız halinde aşağıdaki şartları kabul etmiş sayılırsınız:</p>
              <ul>
                <li>Şahsa veya kuruma hakaret, bireyleri küçük düşürücü durumlar, tehdit veya kişi haklarını tehlikeye düşüren her türlü davranış yasaktır ( bu yasaklar site politikaları doğrultusunda belirlenmiştir)</li>
                <li>Uygunsuz, müstehcen, yasadışı, saygı kurallarını aşan, kişi ya da kurumlara zarar verecek mesajlar yazmak, dosyalar yayınlamak ve bu dosyaların dağıtımını yapmak yasaktır.</li>
                <li>Lisans hakları saklı tutulan Yazılımların ve lisanslama bilgilerinin dağıtımını yapmak (Yazılım Geliştiricisi siz olmadığınız takdirde) yasaktır.</li>
                <li>Gönderilen dosyalarda site kullanıcılarının bilgisayarlarını tehdit edecek bir durum saptanması (virüs, bozuk dosya veya benzeri haller) halinde sorumluluk dosyayı gönderen kişiye aittir. Sitemizdeki dosyaları bilgisayarınızda kullanmadan önce güvenilir kaynaklardan geldiğine emin olun ve mutlaka virüs taraması yapın.</li>
                <li>Sitenizin veya başka bir sitenin reklamını yapmak, alım-satım takas işlemlerini bu site üzerinden yapmaya çalışmak (aksi belirtilmediği sürece) yasaktır.</li>
                <li>Sitedeki anketleri, yarışmaları, veya yazışmaları taklit etmeye çalışmak bir suçtur.</li>
                <li>Yasal yollardan dağıtımı yapılmayan bir dosyayı indirmek ve kullanmak bir suçtur.</li>
                <li>Yazılım geliştiricileri tarafından sağlanan ürünlerin lisanslama bilgilerini değiştirmek veya tamamen silmek kesinlikle yasaktır.</li>
                <li>Komünikasyon Servislerini kullandığınızda kullanıcılarla olan iletişiminizde öncelikle siz sorumlusunuz.</li>
                <li>Komünikasyon Servislerinin işleyişini bozacak davranışlar cezalandırılacaktır..</li>
                <li>Servis Kullanıcılarının kişisel bilgilerini izinleri dışında toplamak, yayınlamak ve dağıtımını yapmak yasaktır.</li>
                <li>Uygulanan yasa ve kurallara uymanız gerekmektedir.</li>
              </ul>

              <p>Ardahan Ticaret ve Sanayi Odası Komünikasyon Servislerini izleme mecburiyetinde değildir. Ancak Ardahan Ticaret ve Sanayi Odası bu servislerde kullanılan materyalleri inceleme veya silme hakkını saklı tutar. Ardahan Ticaret ve Sanayi Odası gerekli gördüğünde Komünikasyon Servislerine erişiminizi haber vermeksizin engelleme hakkını saklı tutar.</p>
              <p>Ardahan Ticaret ve Sanayi Odası yasalar öngördüğü takdirde, işleyişinin devamı için hükümetin isteği doğrultusunda kullanıcılar ile ilgili birtakım bilgiler verebilir.</p>
              <p>Komünikasyon servislerini kullanırken kendiniz veya çocuklarınız ile ilgili daima doğru bilgi vermeniz gerekmektedir. Ardahan Ticaret ve Sanayi Odası yönetimi komünikasyon servislerinin içeriğini (mesajlar, bilgiler) kontrol edemeyebilir. Ardahan Ticaret ve Sanayi Odası Komünikasyon Servislerini kullanmanızla ilgili hiçbir sorumluluk kabul etmemektedir.</p>
              <p>Komünikasyon servislerinde sunulan yazılımlar test amaçlı  ve deneme sürümü olan yazılımlar olabilir. Bu yazılımların kullanımı ve deneme sürelerinin sonunda sisteminizden silinmesi veya satın alma işlemlerinden Ardahan Ticaret ve Sanayi Odası sorumlu değildir.</p>
              <b>Ardahan Ticaret ve Sanayi Odası SİTESİNE SUNULAN VEYA Ardahan Ticaret ve Sanayi Odası SİTESİNDE KULLANILAN MATERYALLER HAKKINDA</b>
              <p>Ardahan Ticaret ve Sanayi Odası kendisine sunulan materyaller üzerinde (tavsiye ve geribesleme de dahil),  Ardahan Ticaret ve Sanayi Odası Websitesi servislerini kullanarak gönderdiğiniz mesajlarda, dosyalarda ve iletişiminiz üzerinde herhangi bir hak talep etmez. Ancak bu servisleri kullandığınız takdirde Ardahan Ticaret ve Sanayi Odası ve ortaklarının /alt kuruluşlarının olası bir denetimi yapabilme hakkı olduğunu kabul etmiş sayılırsınız.</p>
              <b>SORUMLULUKLARLA İLGİLİ</b>
              <p>Ardahan Ticaret ve Sanayi Odası ARACILIĞI İLE SUNULAN HİZMETLER, ÜRÜNLER,SERVİSLER VE BİLGİLER  KESİN OLMAYABİLİR YA DA YAZIM/YAZILIM HATALARINDAN KAYNAKLANAN SORUNLAR OLUŞABİLİR. SİTEDEKİ DEĞİŞİKLİKLER PERİYODİK OLARAK SİSTEME EKLENMEKTEDİR. SİTE İYİLEŞTİRME VEYA GÜNCELLEŞTİRME ÇALIŞMALARI NEDENİYLE SİTE ERİŞMİNDE BAZI DURUMLARDA AKSAKLIKLAR YAŞANABİLİR. HİZMETLERİMİZLE İLGİLİ BİLGİ ALIRKEN DAİMA PROFESYONEL KİŞİLERLE İLETİŞİM KURUNUZ VE ALANINDA UZMAN KİŞİLERE DANIŞINIZ.Ardahan Ticaret ve Sanayi Odası HANGİ KOŞULDA OLURSA OLSUN SİZİ SİTE KULLANIM ŞARTLARINI OKUMAYA TEŞVİK ETMEK ZORUNDA DEĞİLDİR.</p>
              <b>TERMİNASYON/ERİŞİM SINIRLAMASI</b>
              <p>Ardahan Ticaret ve Sanayi Odası uyarmaksızın Ardahan Ticaret ve Sanayi Odası Web Sitesine erişiminizin sınırlandırılması veya tamamen yasaklanması gibi işlemleri yapabilir. Yasal işlemlerde Ulusal ve Uluslararası kanunlar geçerlidir.</p>
              <b>TELİF HAKKI ve MARKA NOTLARI:</b>
              <p> Ardahan Ticaret ve Sanayi Odası  : Copyright 2024 by DY</p>
              <b>MARKA</b>
              <p>Sitemizde kullanılan ürünlerin ve servislerin marka hakları ürün ve servis sağlayıcılarına aittir.Burada bahsedilmeyen tüm haklar saklıdır.</p>
              <b>MARKA HAKLARININ İHLALİ DURUMUNDA</b>
              <p>Site yönetimi ile temasa geçip prosedür uyarınca gerekli bilgiyi vermeniz halinde ihlali yapan kişi veya kurum hakkında yasal işlem başlatılacaktır. Prosedüre uymayan ihbarlar yanıtlanmayacaktır.</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
