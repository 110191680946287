import React from 'react';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function meclisUyeleri() {
  return (
    <div>
      <Helmet>
        <title>Meclis Üyeleri</title>
        <meta name="description" content="Meclis Üyeleri" />
        <meta name="keywords" content="Meclis Üyeleri" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Meclis Üyeleri</span>
              </div>
              <div className="announcements">
                <div className="container">
                  <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-sm-12 col-lg-12">
                      <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>


                          <div class="card" style={{ width: '18rem' }}>
                            <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                            <div class="card-body">
                              <h5 class="card-title">Soner GÖRMÜŞ</h5>
                              <span>Meclis Başkanı</span>
                            </div>
                          </div>


                        </div>

                        <div className="row cardContainer" >



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Erhan DEMİR</h5>
                                <span>1. Meclis Bşk. Yrd.</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Mahmut Ahmet VURAL</h5>
                                <span>2. Meclis Bşk. Yrd.</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Metin LALE</h5>
                                <span>Katip Üye</span>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className="row cardContainer">



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Sebahattin GÜNDOĞDU</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Veyis OSMANOĞLU</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Çetin DEMİRCİ</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className="row cardContainer">



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Burhan Buudak</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Muharrem ÇAPAN</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">İsmail ALTUNSOY</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="row cardContainer">



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">İsmail KAHYA</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Cenk Eren TURAN</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Turgay ŞİRİN</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="row cardContainer">



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Yusuf Rezzak POLAT</h5>
                                <span>Meclis Üyesi</span>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
