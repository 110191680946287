import React from 'react'
import { Helmet } from 'react-helmet';
export default function Basin() {
  
  return (
    <div>
      <Helmet>
            <title>Basın</title>
            <meta name="description" content="Basın" />
            <meta name="keywords" content="Basın" />
        </Helmet>
      basin

    </div>
  )
}
