import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

export default function Tarihcemiz() {
   
    return (
        <div className="container">
            <Helmet>
            <title>Tarihçemiz</title>
            <meta name="description" content="Tarihcemiz" />
            <meta name="keywords" content="Tarihcemiz" />
        </Helmet>
            <div className="row">
                <div className="col-lg-12">
                    <div className="announcementsContainer">
                        <div className="innerAnnouncements">
                            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                            <span>Tarihçemiz</span>
                        </div>
                        <div className="announcements">
                            <h5 className='mt-5'>KURULUŞ VE AMACI:</h5>
                            <p>Ardahan Ticaret ve Sanayi Odası 5590 Sayılı Kanunun Hükümleri uyarınca 1926 yılında kurulmuş olup üyelerinin müşterek ihtiyaçlarını karşılamak,mesleki faaliyetlerini kolaylaştırmak, mesleğin genel menfaatlere uygun olarak gelişmesini sağlamak, mensupların birbiriyle ve halk ile olan ilişkilerinde dürüstlüğü ve güveni hakim kılmak üzere meslek disiplin ahlak ve tesennüdünü korumak ve bu kanunda yazılı hizmetleri yerine getirmek amacıyla kurulan kamu kurumu niteliğinde tüzel kişiliğe sahip meslek kuruluşudur.</p>
                            <h5 className='mt-5'>ODANIN ORGANLARI:</h5>
                            <ul>
                                <li>Meslek Komiteleri</li>
                                <li>Oda Meclisi</li>
                                <li>Oda Yönetiminden Oluşmaktadır.</li>
                            </ul>
                            <p className='mt-5'>Oda üyeleri arasında (4) yılda bir genel seçim yapılarak oda meslek komiteleri belirlenir.
                                Meslek komitelerinin görev süresi de (4) yıldır. Meslek komiteleri oda meclisini,Oda meclisi de oda yönetim kurulunu belirler.oda meclisi üye durumuna göre Ardahan için (14) kişiden oda yönetimi ise (5) kişiden oluşur.
                                Odanın hukuki temsilcisi oda yönetim kurulu başkanıdır.
                                Odanın personel sayısı iki kadrolu ve iki sözleşmeli olmak üzere 4 kişidir.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
