import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet';
export default function EkspertizRaporu() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Ekspertiz Raporu</title>
            <meta name="description" content="Ekspertiz Raporu" />
            <meta name="keywords" content="Ekspertiz Raporu" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Ekspertiz Raporları Birimi Genel İşlemleri</span>
          </div>
          <div className="announcements">
           <p>Gayrimenkul rayiç bedelinin belirlenmesi için hazırlanan belgedir. Rayiç bedeli belirlenmesi istenen gayrimenkulun tapu fotokopisinin eklendiği dilekçe ile başvurulması gerekmektedir. Ücrete tabi olan bu belgeler üyeye verilirken Oda Meclisi’nce belirlenen hizmet karşılığı bedel tahsil olunur.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
