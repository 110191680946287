// src/components/ExampleCarouselImage.jsx
import React from 'react';

export default function ExampleCarouselImage({ imageUrl, text }) {
  return (
    <div className="carousel-image" style={{height:'600px'}}>
      <img
        className="d-block w-100"
        src={imageUrl}
        alt={text}
        style={{borderRadius:'9px',height:'600px'}}
      />
      <div className="carousel-caption">
        <h3>{text}</h3>
      </div>
    </div>
  );
}
