import React from 'react';
import Weather from '../components/weather';
export default function Header() {
    return (
        <div className='headerContainer'>
            <Weather></Weather>

            <div className="headersContainer2">
                <div>
                    <img src="/akredite-oda-transparan.png" alt="" />
                </div>
                <div className='vektorContainer2'>
                    <img src="/ardahantso-vektor3.svg" alt="" />
                </div>
                <div className='ataBayrak'>
                    <img src="/atabayrak.png" alt="" />
                </div>
            </div>
        </div>
    );
}
