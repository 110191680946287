import React from 'react'
import {Helmet} from 'react-helmet'
export default function KaliteVeAkreditasyon() {
  
  return (
    <div>
      <Helmet>
            <title>Kalite ve Akreditasyon</title>
            <meta name="description" content="Kalite ve Akreditasyon" />
            <meta name="keywords" content="Kalite ve Akreditasyon" />
        </Helmet>
      kaliteVeAkreditasyon

    </div>
  )
}
