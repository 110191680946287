import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function YaziIsleri() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Yazı İşleri</title>
            <meta name="description" content="Yazı İşleri" />
            <meta name="keywords" content="Yazı İşleri" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Yazı İşleri Birimi Genel İşlemleri</span>
          </div>
          <div className="announcements">
            <p>Oda’ya gelen ve Oda’dan gönderilen bütün evrakların, kayıtlarını yaparak, ilgili servis amirliklerine iletir ve cevap verilen evrakların dağıtım, postalama ve dosyalama işlemlerini yapar.</p>
            <p>Yönetim Kurulu, Meclis ve Meslek Komitesi Toplantılarının duyurulması, organizasyonu, toplantı kararlarının yazılı hale getirilmesi, imzalattırılması, alınan kararların ilgililere bildirilmesi ve takibinin yapılmasını sağlar.</p>
            <p>Oda ile ilgili postalama işlemlerinin yerine getirilmesi.</p>
            <p>Genel Sekreter tarafından verilecek diğer görevlerin yerine getirilmesini sağlar.</p>
            <p>5174 sayılı Mevzuat uyarınca istenen bilgi ve belgeleri sicil kayıtlarına göre vermek</p>
            <p>5174 sayılı Mevzuat uyarınca üyenin talebi üzerine istenilen belgeleri tanzim etmek</p>
            <p>Üyelerimizin ve kamu kurum ve kuruluşlarının talebi üzerine Oda servislerinde hazırlanan yazıları, giden evrak kayıt defterine kayıt etmek,</p>
            <p>Kamu kurum ve kuruluşlarından istenen firma istihbarat bilgilerini talebe bağlı olarak yazılı ve sözlü cevap vermek,</p>
            <p>Odaya bağlı üyelerimizin yanında çalışan çırakların, çıraklık okuluna devamı durumunda çıraklık dosyalarını tutmak, kayıt işlemlerini yapmak ve nakil işlemlerinin yapılmasını sağlamak,</p>
            <p>Bağ-kur formlarını üye sicil bilgilerine göre onaylamak,</p>
            <p>Kamu kurum ve kuruluşlarının ihale öncesi bir malın veya hizmetin piyasadaki yaklaşık maliyetinin tespiti için yazılı başvuru yapmaları halinde yaklaşık maliyet fiyat tespitleri yapmak</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
