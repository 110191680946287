import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

export default function KalitePolitikasi() {
  
  return (
    <div className="container">
       <Helmet>
            <title>Kalite Politikası</title>
            <meta name="description" content="Kalite Politikası" />
            <meta name="keywords" content="Kalite Politikası" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Kalite Politikamız</span>
            </div>
            <div className="announcements">
              <h5 className='mt-5'>KALİTE VE AKREDİTASYON POLİTİKAMIZ</h5>
              <p>TOBB kanunu olan 5174 sayılı yasa ile, mevzuatlara uygun olarak görev ve sorumluluklarımızı, önceliğimiz olan üye memnuniyeti sağlayarak yerine getireceğiz. Yönetici ve personelimizle kendimizi ve verdiğimiz hizmet kalitesini, Kalite Yönetim Sistem şartlarına uygun olarak, sürekli geliştireceğiz.
                TOBB 5 yıldızlı hizmet anlayışı ile özetlenen akreditasyon sistemimizi geliştirecek, üyemizin, ilimiz ve bölgemizin kalkınmasına yönelik her türlü etkinlikleri düzenleyeceğiz.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
