import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

export default function Ar_geBirimi() {

  return (
    <div className="container">
      <Helmet>
        <title>Ar-Ge Birimi</title>
        <meta name="description" content="Ar-Ge Birimi" />
        <meta name="keywords" content="Ar-Ge Birimi" />
      </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>AR-GE Birimi Genel Hizmetleri</span>
            </div>
            <div className="announcements">
              <p>Bölgenin sosyo-ekonomik durumu ile ilgili aylık veya yıllık raporlar tanzim etmek, istatistiksel çalışmalar yapmak,

                Dünya, Türkiye ve Ardahan  bazında genel ekonomik ve sosyal konular ile üye memnuniyetini ölçme konularında anket çalışmaları yapmak, anket sonuçlarını analiz etmek, Yönetim Güzden Geçirme Toplantısına sunulmak üzere Kalite Yönetim Temsilcisine ve üst makamlara bildirmek,

                Dünya, Türkiye ve Ardahan hakkında istatistiki bilgi talep eden ilgililere bilgi sunmak,

                AB projeleri hakkında çalışma yapmak

                DİE tarafından açıklanan TEFE-TÜFE oranları ile günlük döviz kurlarının derlenmesini ve talep halinde ilgililere iletilmesini sağlamak,

                Genel Sekreter tarafından AR-GE Memurluğu’na havale edilen yazılara cevap vermek  </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
