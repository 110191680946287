import React from 'react';
import {Helmet} from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function meclisKomitesiUyeleri() {
  return (
    <div>
      <Helmet>
            <title>Meclis Komitesi Üyeleri</title>
            <meta name="description" content="Meclis Komitesi Üyeleri" />
            <meta name="keywords" content="Meclis Komitesi Üyeleri" />
        </Helmet>



        <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Meclis Komitesi Üyeleri</span>
              </div>
              <div className="announcements">
                <div className="container">
                  <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-sm-12 col-lg-12">
                      <div className="row" style={{ justifyContent: 'center' }}>

                        <div className="row cardContainer" >



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Özmen Demir</h5>
                                <span>1. Meslek Komitesi Başkanı</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Ali Özgür</h5>
                                <span>1. Meslek Komitesi Bşk Yrd.</span>
                              </div>
                            </div>
                          </div>



                       

                        </div>


                        <div className="row cardContainer">



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Mükerrem Kargı</h5>
                                <span>2. Meslek Komitesi Başkanı</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Vural Badem</h5>
                                <span>2. Meslek Komitesi Bşk Yrd.</span>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className="row cardContainer">



            
                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Turgay Şirin</h5>
                                <span>3. Meslek Komitesi Başkanı</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Kenan Özkamar</h5>
                                <span>3. Meslek Komitesi Bşk Yrd.</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="row cardContainer">



                        
                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Hakan Kanpolat</h5>
                                <span>4. Meslek Komitesi Başkanı</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Sevim Köseliören</h5>
                                <span>4. Meslek Komitesi Bşk Yrd.</span>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="row cardContainer">



                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Bora Aydın</h5>
                                <span>5. Meslek Komitesi Başkanı</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Dursun Şirin</h5>
                                <span>5. Meslek Komitesi Bşk Yrd.</span>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
