import React from 'react'
export default function Footer() {
  return (
    <footer>
        <ul>
            <h5>İletişim</h5>
            <li>Ardahan Ticaret ve Sanayi Odası Karagöl Mah. Kongre Cad. No:67
            Kat:4-5 Merkez/ARDAHAN</li>
            <li> Telefon: +90(478) 211 29 70</li>
            <li> Fax: +90(478) 211 30 36</li>
            <li> E-posta: ardahantso@tobb.org.tr</li>
        </ul>

        <ul>
          <h5>Bağlantılar</h5>
          <li>
            <a href='/kullanimSartlari'>Kullanım Şartları</a>
          </li>
          <li>
            <a href='/gizlilikSozlesmesi'>Gizlilik Sözleşmesi</a>
          </li>
          <li>
            <a href='/iletisim'>İletişim Formu</a>
          </li>
          <li>
            <a href='/kurumsalLogo'>Kurumsal Logo</a>
          </li>
          <li>
            <a href='/organizasyonSemasi'>Kurum Organları</a>
          </li>
        </ul>

        <ul>
          <h5>Hakkımızda</h5>
          <li>
            <a href='/tarihcemiz'>Ardahan TSO Hakkında</a>
          </li>
          <li>
            <a href='/misyonVisyon'>Misyon ve Vizyon</a>
          </li>
          <li>
            <a href='/kalitePolitikasi'>Kalite Politikamız</a>
          </li>
          <li>
            <a href='/eskiBaşkanlarimiz'>Eski Başkanlarımız</a>
          </li>
          <li>
            <a href='/kurumsalLogo'>Kurumsal Logomuz</a>
          </li>
        </ul>

        <ul>
          <h5>Odamız</h5>
          <li>
            <a href='/yonetimKuruluUyeleri'>Yönetim Kurulu</a>
          </li>
          <li>
            <a href='/meclisUyeleri'>Meclis Üyeleri</a>
          </li>
          <li>
            <a href='/tarihce'>Ardahan Tarihçesi</a>
          </li>
          <li>
            <a href='/haberler'>Faaliyetler</a>
          </li>
        </ul>
    </footer>
  )
}
