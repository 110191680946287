import React from 'react'
import { Helmet } from 'react-helmet'
export default function VideoGaleri() {
  
  return (
    <div>
      <Helmet>
            <title>Video Galeri</title>
            <meta name="description" content="Video Galeri" />
            <meta name="keywords" content="Video Galeri" />
        </Helmet>
      videoGaleri

    </div>
  )
}
