import React from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function Ekonomi() {

  return (
    <div>
      <Helmet>
        <title>Ekonomi</title>
        <meta name="description" content="Ekonomi" />
        <meta name="keywords" content="Ekonomi" />
      </Helmet>



      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Ekonomi</span>
              </div>
              <div className="announcements">
                <strong>Ekonomik Faaliyetler</strong>
                <p>Ardahan ilinin ekonomisi hayvancılık ve tarıma dayanır. Sanayi ve turizm sektörü fazla gelişmemiştir.</p>
                <strong>Hayvancılık:</strong>
                <p>
                  Geniş mer'a ve çayırlarındaki zengin bitki örtüsü hayvancılığın gelişmesini sağlamıştır. En çok sığır ve koyun beslenir. Koyun yetiştiriciliğinde mor ve ak karaman, sığır besiciliğinde ise yüksek verimli Doğu Andolu kırmızısı vezavod veya kalakan türleri hakimdir. Arıcılık gelişmiştir.
                </p>
                <strong>Tarım:</strong>
                <p>
                  Elverişsiz iklim şartları tarla tarımını engeller. Akarsu boylarında tarım yapılır. Buğday, arpa, çavdar ve az miktarda patates yetiştirilir.
                </p>
                <strong>Sanayi:</strong>
                <p>
                  Ardahan'da sanayi fazla gelişmemiştir. İlde hayvancılığa bağlı olarak süt toplama merkezleri, küçük çaplı mandıralar yer alır. En büyük sanayi kuruluşu Karset'tir. Bu kuruluş hayvan besiciliği ve hayvan ürünleri imalatı yapar.
                </p>
                <strong>Ulaşım:</strong>
                <p>
                  Ulaşım karayoluyla yapılır. Demiryolu bağlantısı ve havaalanı yoktur.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
