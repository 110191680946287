import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function BasinYayinDepartmani() {
 
  return (
    <div className="container">
      <Helmet>
            <title>Basın Yayın Departmanı</title>
            <meta name="description" content="Basın Yayın Departmanı" />
            <meta name="keywords" content="Basın Yayın Departmanı" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Basın-Yayın Birimi Genel Hizmetleri</span>
            </div>
            <div className="announcements">
              <p>Ardahan Ticaret ve Sanayi Odası tarafından tacirlere, işadamlarına, sanayicilere yönelik olan, organize edilen toplantı, eğitim, seminer, konferans programlarını duyurmak ve organize etmek.

Ardahan Ticaret ve Sanayi Odası Aylık Bültenini hazırlamak, Genel Sekretere ve Yayın Kuruluna onaylatmak ve üyelere dağıtımını yaptırmak.

Yönetim Kurulu ve Meclis Toplantıları ile Toplantılarını duyurmak,

Basın toplantılarını ve basın bildirilerini gerekli basın ve medya kuruluşlarının yetkililerine ulaştırmak,

Ardahan Ticaret ve Sanayi Odası ile ilgili basında yer alan haberleri ve bildirileri Basında TSO dosyasında muhafaza etmek,

Yurtiçi ve yurtdışı fuarları posta, faks ve mail yoluyla gelen duyurular ile takip etmek, web sayfaları, kitaplardan araştırmak ve Yozgat Ticaret ve Sanayi Odası tarafından yayınlanan Aylık Bülten aracılığı ile üyelere duyurmak.

Yurtiçi ve yurtdışı fuarlar için üyelerden talep geldiğinde davetiye temin etmek,

Meclis Başkanı, Yönetim Kurulu Başkanı ve üyeleri adına Yozgat Ticaret ve Sanayi Odasına gelen tüm yazıların ve davetiyelerin takibini ve tanzimini yapmak. </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
