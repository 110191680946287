import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function GizlilikSozlesmesi() {
  return (
    <div className='container'>
      <Helmet>
        <title>Gizlilik Sözleşmesi</title>
        <meta name="description" content="Gizlilik Sözleşmesi" />
        <meta name="keywords" content="Gizlilik Sözleşmesi" />
      </Helmet>
      <div className="row">
        <div className="col-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Gizlilik Sözleşmesi</span>
            </div>
            <div className="announcements">
              <p>Ardahan Ticaret ve Sanayi Odası Yönetimi gizliliğinizi korumak ve sunulan teknolojilerden en üst seviyede yararlanmanızı sağlamak amacıyla bir takım güvenlik ve gizlilik ilkeleri belirlemiştir. Belirlenen ilkeler Ardahan Ticaret ve Sanayi Odası websitesi ve tüm alt-sitelerinde veri aktarımı ve kullanımı konularında uygulanacaktır. Ardahan Ticaret ve Sanayi Odası websitesini kullanmakla belirtilen ilkeleri kabul etmiş sayılırsınız.</p>
              <b>Kişisel Bilgilerin Güvenliği Hakkında</b>
              <p>Ardahan Ticaret ve Sanayi Odası eposta adresiniz, adınız, ev ya da iş adresiniz veya telefon numaranız gibi kişisel bilgilerinizi kayıt anında veya servis kullanımlarında gerektiğinde isteyebilir. Ardahan Ticaret ve Sanayi Odası kişisel bilgiler dışında paylaşımlı bilgilerinizi de bildirmenizi isteyebilir ( posta kodu, yaş, cinsiyet, ilgi alanları gibi)</p>
              <p>Ayrıca Ardahan Ticaret ve Sanayi Odası tarafından bilgisayarınızla ilgili donanımsal veya yazılımsal özellikler otomatik olarak toplanabilir. Bu özellikler IP Adresiniz, Tarayıcı Türü, Alan adları ve Web Sitesine Erişim Zamanları ve referans siteler gibi değerler olabilir. Bu bilgilerin toplanmasındaki amaç Ardahan Ticaret ve Sanayi Odası sitesinin işleyişindeki verimliliği ve kaliteyi arttırmak, Ardahan Ticaret ve Sanayi Odası sitesinin kullanımı ile ilgili genel istatistiksel veriler toplamaktır.</p>
              <p>Önemli Uyarı :  Lütfen şahsi bilgilerinizi Ardahan Ticaret ve Sanayi Odası tarafından sunulan özel mesajlaşma alanlarında güvenmediğiniz kişilerle paylaşmayın. Unutmayın bu bilgiler başka kullanıcılar tarafından ele geçirilebilir ve sizin zararınıza kullanılabilir. Ardahan Ticaret ve Sanayi Odası çevrimiçi mesajlaşmalarınızı kontrol etmemektedir.</p>
              <p>Ardahan Ticaret ve Sanayi Odası Yönetimi ziyaret ettiğiniz web sitelerindeki Gizlilik İlkelerini okumanızı önerir. Böylece kişisel bilgilerinizin güvenli bir ortamda saklanıp saklanmadığını öğrenebilirsiniz. Ardahan Ticaret ve Sanayi Odası sitesi Ardahan Ticaret ve Sanayi Odası dışında yayınlanan Gizlilik İlkeleri veya diğer açıklamalardan sorumlu değildir.</p>
              <b>Kişisel Bilgilerin Kullanımı Hakkında</b>
              <p>Ardahan Ticaret ve Sanayi Odası kişisel bilgilerinizi istekte bulunduğunuz veya satın aldığınız  hizmetleri sunabilmek ve Ardahan Ticaret ve Sanayi Odası sitesinin bu servislere erişiminizi sağlayacak şekilde programlanmasını sağlamak amacıyla kullanır.  Ardahan Ticaret ve Sanayi Odası ayrıca kimlik bilgilerinizi satın alma, aboneliklerden ve kampanyalardan  yararlanma aşamalarında yüksek güvenliği sağlamak amacıyla kullanır. Ardahan Ticaret ve Sanayi Odası sizinle anketler aracılığı ile bağlantı kurabilir ve sunulan ya da sunulacak hizmetler hakkında görüşlerinizi isteyebilir.</p>
              <p>Ardahan Ticaret ve Sanayi Odası üye ya da müşteri listesini üçüncü şahıs ya da kurumlara satmaz, kiralamaz ya da devretmez. Ardahan Ticaret ve Sanayi Odası zaman zaman iş-partnerleri aracılığı ile sizinle bağlantı kurabilir ve ilginizi çekebilecek çeşitli servis tekliflerinde bulunabilir. Bu tür durumlarda kişisel bilgileriniz üçüncü şahıslara transfer edilmez. Ardahan Ticaret ve Sanayi Odası güvenilen partnerleri ile istatistiksel analiz, telekominikasyon, müşteri hizmetleri, posta veya kargo servisleri gibi alanlarda işbirliği yapabilir. Üçüncü şahıs ve kurumların bu servislerin gerçekleştirilmesi amacı dışında bilgilerinizi kullanması yasaklanmıştır. Ardahan Ticaret ve Sanayi Odası iş-partnerleri kişisel bilgilerinizin güvenliğinden sorumludur.</p>
              <p>Ardahan Ticaret ve Sanayi Odası apaçık izniniz olmadan şahsınızla ilgili ırk, din, politika gibi konuların Ardahan Ticaret ve Sanayi Odası sitesinde yer almasına izin vermez.</p>
              <p>Ardahan Ticaret ve Sanayi Odası yönetimi Ardahan Ticaret ve Sanayi Odası sitesi tarafından sunulan hizmetlerin popülerliğini tespit etmek amacıyla ziyaret ettiğiniz sayfaları izleyebilir. Bu veri reklam-partnerlerinin siteye vereceği reklamlarda kullanılacağı içeriği belirlemesine yardımcı olmak amacıyla kullanılabilir.</p>
              <p>Ardahan Ticaret ve Sanayi Odası Web sitesi uyarmaksızın kişisel bilgilerinizi kesinlikle yayınlamaz. Ancak, yasal durumlarda veya bu bilginin yayınlanmasının uygun olacağına inandığımızda bu işlem gerçekleştirilir:  (a) Ardahan Ticaret ve Sanayi Odası tarafından sunulan servislerin yasadışı kullanımı; (b) Ardahan Ticaret ve Sanayi Odası sitesinin haklarının savunulması; ve, (c) Ardahan Ticaret ve Sanayi Odası kullanıcılarının güvenliğini tehdit edecek unsurlarda .</p>
              <b>Çerezlerin Kullanımı Hakkında</b>
              <p>Ardahan Ticaret ve Sanayi Odası Web sitesi erişiminizi kolaylaştırmak ve kişiselleştirmek amacıyla "çerez" (cookie) sistemini kullanır.  Çerez websitesi sunucusu tarafından bilgisayarınıza kaydedilen bir metin dosyasıdır. Çerezler bir programı çalıştırmak ya da bilgisayarınıza virüs bulaştırmak gibi amaçlarla kullanılamaz. Her çerez sadece bir kullanıcıya atanmıştır ve sadece atayan sunucu tarafından okunabilir.</p>
              <p>Çerez kullanımının özel faydalarından biri web site ziyaretlerinizde size zaman kazandırmasıdır. Söz gelimi; Ardahan Ticaret ve Sanayi Odası sitesine ve/veya servislerine üye olduğunuzda siteyi ya da servislerle ilgili sayfayı bir sonraki ziyaretinizde otomatik olarak giriş yapmanızı sağlamak için sisteminize kaydedilen çerezlerden yararlanılır. Bu şekilde bazı bilgilerinizi tekrar girmenize gerek kalmaz. Siteyi tekrar ziyaret ettiğinizde Ardahan Ticaret ve Sanayi Odası üzerinde özelleştirdiğiniz kullanım alanlarından faydalanabilirsiniz.</p>
              <p>Çerezleri kullanma ya da kullanmama tercihi tamamen size aittir. Web tarayıcıları genellikle çerezleri otomatik olarak kabul edecek şekilde programlanmıştır. Eğer çerezleri pasif hale getirmek istiyorsanız bu işlemi Tarayıcınızın ilgili ayarlarını özelleştirerek gerçekleştirebilirsiniz. Çerezleri kullanmama tercihi yaparsanız, Ardahan Ticaret ve Sanayi Odası tarafından sunulan interaktif servislerden tam olarak faydalanamayabilirsiniz.</p>
              <b>Kişisel Bilgiler Hakkında</b>
              <p>Ardahan Ticaret ve Sanayi Odası kişisel bilgilerinizi yetkisiz erişimlerden, kullanımlardan ve açığa vurma işlemlerinden korur. Ardahan Ticaret ve Sanayi Odası kimlik bilgilerinizi güvenli ortamda yetkisiz erişimlerden korunacak şekilde saklar. Eğer kişisel bilgileriniz başka web sitelerine transfer edilecek olursa bu işlem için şifreleme yöntemi (SSL= Secure Socket Layer Protokolü) kullanılır.</p>
              <b>Bilgi</b>
              <p>Ardahan Ticaret ve Sanayi Odası müşteri ya da şirket bildirimleri doğrultusunda Gizlilik İlkeleri bölümünde düzeltme ve güncelleme gibi işlemleri yapabilir. Ardahan Ticaret ve Sanayi Odası Gizlilik İlkeleri bölümünü belli aralıklarla okumanızı tavsiye eder. Bu şekilde Kişisel Bilgilerinizin Güvenliği konusundaki gelişmelerden haberdar olursunuz.</p>
              <b>İletişim Bilgileri</b>
              <p>Ardahan Ticaret ve Sanayi Odası Gizlilik İlkeleri ile ilgili görüş ve önerilerinize açıktır. Eğer Ardahan Ticaret ve Sanayi Odası sitesinin belirtilen Gizlilik İlkelerini uygulamadığını düşünüyorsanız lütfen ardahantso@probase.com.tr adresinden bize ulaşın. Sorunları saptamak ve çözüm yolları üretmek Ardahan Ticaret ve Sanayi Odası yönetiminin görevidir.</p>
              <b>Mobile Uygulamamız Tarafından Toplanan Bilgiler Hakkında</b>
              <ul>
                <li>İşletim sistemi izin verdiğinde, uygulamamız sınırlı bir listeye bağlı olarak, cihaz üzerinde uygulamaların yüklü olup olmadığını kontrol edebilir.</li>
                <li>Son Kullanıcı’nın uygulamadaki etkileşimlerine ilişkin bilgiler toplayabilir.</li>
                <li>Mobil reklam tanımlayıcıları kullanabilir: örnek iOS IDFA ve Android Reklam Kimliği (Android Advertising ID). Bu tanımlayıcılar belki başka bilgiler ile ilişkili olabilir.</li>
                <li>IP adresi</li>
                <li>Kullanılan cihaz ile ilgili bilgiler: Örnek cihaz tipi (mobil, tablet); İlletim sistemi ve versiyonu (Android, iOS vs); Şebeke sağlayıcısı; mobil browser (Safari, Chrome, vs); dil ayarları; saat dilimi; ve network tipi (WiFi vs).</li>
              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
