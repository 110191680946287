import React from 'react';
import { Helmet } from 'react-helmet';
export default function HaberEkle() {
  
  return (
    <div>
      <Helmet>
            <title>Haber Ekle</title>
            <meta name="description" content="Haber Ekle" />
            <meta name="keywords" content="Haber Ekle" />
        </Helmet>
      <h1>Haber Ekle</h1>
    </div>
  );
}
