import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet';

export default function HalklaIliskilerBirimi() {
 
  return (
    <div className="container">
      <Helmet>
            <title>Halkla İlişkiler Birimi</title>
            <meta name="description" content="Halkla İlişkiler Birimi" />
            <meta name="keywords" content="Halkla İlişkiler Birimi" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Halkla İlişkiler Birimi Genel Hizmetleri</span>
          </div>
          <div className="announcements">
            <p>Ardahan Ticaret ve Sanayi Odası tarafından tacirlere, işadamlarına, sanayicilere yönelik olarak organize edilen toplantı, eğitim, seminer, konferans ve gezi programlarını duyurmak ver organize etmek.

İlimiz ve üyelerimizden gelen talepler doğrultusunda iştirakçi kurum ve kuruluşlarla ortaklaşa eğitim programları ve seminerler düzenlemek, organizasyonunu yapmak, devamlılığını sağlamak, Yozgat Ticaret ve Sanayi Odası Aylık Bültenini hazırlamak, Genel Sekretere ve Yayın Kuruluna onaylatmak ve üyelere dağıtımını programlamak.

Ardahan Ticaret ve Sanayi Odası Bülteni’nin yayımı dışında Ardahan Ticaret ve Sanayi Odası’nın yazılı, görsel ve işitsel basın ile olan ilişkilerini, kamuoyu oluşturma çalışmalarını gerçekleştirmek üzere çalışmalar yürütmek

Kamuoyunu Odamız çalışmaları Yönetim Kurulumuzun görüşleri konularında yazılı ve görsel basın aracılığıyla bilgilendirmek. Basın toplantılarını ve basın bildirilerini duyurmak.

Ardahan Ticaret ve Sanayi Odası ile ilgili basında yer alan haberleri ve bildirileri Basında Yozgat Ticaret ve Sanayi Odası dosyasında muhafaza etmek.

Ardahan Ticaret ve Sanayi Odası ’nın yıl boyunca yaptığı tüm eğitim, organizasyon, seminer, basın toplantısı vs faaliyetlerin dokümanlarını muhafaza etmek, Yurtiçi ve yurtdışı fuarları posta, faks ve mail yoluyla gelen duyurular ile takip etmek, araştırmak ve Yozgat Ticaret ve Sanayi Odası tarafından yayınlanan Aylık Bülten aracılığı ile üyelere duyurmak.

Yurtiçi ve yurtdışı fuarlar için üyelerden talep geldiğinde davetiye temin etmek.

Yönetim Kurulu Başkanı adına Ardahan Ticaret ve Sanayi Odası gelen tüm yazıların ve davetiyelerin takibini ve tanzimini yapmak.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
