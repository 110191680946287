import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
export default function MisyonVisyon() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Misyon Ve Vizyon</title>
            <meta name="description" content="Misyon Ve Vizyon" />
            <meta name="keywords" content="Misyon Ve Vizyon" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Misyon-Vizyon</span>
            </div>
            <div className="announcements">
              <h5 className='mt-5'>MİSYONUMUZ</h5>
              <p>Şeffaf ve hesap verilebilirlik ilkesine bağlı, üyelerine kaliteli ve hızlı hizmet veren, üyelerinin taleplerine uygun nitelikli projeler üretip onların hizmetine sunan, Ahilik değerlerine bağlı oda hizmeti sunmaktır.</p>
              <h5 className='mt-5'>VİZYONUMUZ</h5>
              <p>Önümüzdeki 10 yıl içerisinde özellikle turizm sektörünün gelişimini sağlayıp Ardahan’ı bölgenin en aktif ili yaparak, üyelerine ve diğer kurumlara öncülük eden bir oda olmak.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
