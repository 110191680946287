import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

export default function IthalatciFirmalar() {
    const [firmalar, setFirmalar] = useState([]);
    const apiUrl = process.env.REACT_APP_API_STRING;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${apiUrl}/addImporterCompany`);
                setFirmalar(response.data);
            } catch (error) {
                console.error('There was an error fetching the data!', error);
            }
        };

        fetchData();
    }, [apiUrl]);

    return (
        <div className='container'>
            <Helmet>
                <title>İthalatçı Firmalar</title>
                <meta name="description" content="İthalatçı Firmalar" />
                <meta name="keywords" content="İthalatçı Firmalar" />
            </Helmet>

            <h1>İthalatçı Firmalar</h1>
            <table className="table">
                <thead>
                    <tr>
                        <th>Üye Sicil No</th>
                        <th>Unvan</th>
                        <th>Adres Tipi</th>
                        <th>Adres</th>
                        <th>İş Telefonu</th>
                        <th>Üye Kayıt Tarihi</th>
                        <th>Nace Faaliyet Kodu</th>
                        <th>Nace Faaliyet Adı</th>
                        <th>Durumu</th>
                    </tr>
                </thead>
                <tbody>
                    {firmalar.map((firma, index) => (
                        <tr key={index}>
                            <td>{firma.uyeSicil}</td>
                            <td>{firma.unvan}</td>
                            <td>{firma.adresTip}</td>
                            <td>{firma.adres}</td>
                            <td>{firma.isTelefon}</td>
                            <td>{firma.uyeKayitTarihi}</td>
                            <td>{firma.naceKodu}</td>
                            <td>{firma.naceAdi}</td>
                            <td>{firma.durumu}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
