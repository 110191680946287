import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function KapasiteRaporu() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Kapasite Raporu</title>
            <meta name="description" content="Kapasite Raporu" />
            <meta name="keywords" content="Kapasite Raporu" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Kapasite Raporları Birimi Genel İşlemleri</span>
            </div>
            <div className="announcements">
              <p>
                Firmaların iş yerinde mevcut makine parkı ve personel durumuna göre yıllık üretim ve tüketimlerini gösteren belgedir. Odamız tarafından verilen matbu dilekçenin doldurulması ve dilekçe ekinde istenilen belgelerin ibrasından sonra işyeri tetkik edilir, rapor hazırlandıktan sonra onay için Türkiye Odalar ve Borsalar Birliği’ne gönderilir.
                Bu raporun geçerlilik süresi Odalar Birliği’nin tasdik tarihinden itibaren 3 yıldır. Süresi biten ve kapasite değişikliği olan firmaların raporlarının yenilenmesi için yukarıda bahsedilen işlemler yapılır. Süre bitiminde yenilenmeyen raporlar Odalar Birliği’nce iptal edilir.
              </p>
              <strong>Kapasite Raporu Hangi Alanlarda Kullanılır?</strong>
              <ul>
                <li>Yasal bir zorunluluk olan ve indirimli tarifeden elektrik kullanımı için gerekli görülen Sanayici Sicil Belgesi Müracaatlarında</li>
                <li>Çeşitli kurum ve kuruluşların ihalelerine iştirakte</li>
                <li>Sanayici-ihracatçılara tanınan haklardan yararlanmada</li>
                <li>Bazı üretim girdilerini indirimli tarifeden temin etmekte</li>
                <li>Sanayicilere verilen tahsislerden yararlanmada</li>
                <li>Kota başvurularında</li>
                <li>Teşvik Belgesi taleplerinde</li>
                <li>Geçici Kabul Yoluyla ithalat girişimlerinde</li>
                <li>İmalatçı belgesi alımında</li>
                <li>Vergi incelemelerinde</li>
              </ul>
              <strong>Kapasite Raporunun Müracaatı İçin Gerekli Belgeler :</strong>
              <ul>
                <li>Ticaret ve Sanayi Odasına Hitaben Dilekçe,</li>
                <li>Kapasite Raporu Talep Formu,</li>
                <li>Türkiye Odalar ve Borsalar Birliği hesabına yatan ücret dekontu aslı,</li>
                <li>Son iki aya ait işçi SSK Bildirgesi ve ödeme makbuzları,</li>
                <li>Makinelerin alış faturaları ya da sahiplik belgeleri,</li>
                <li>Finansal Kiralama yolu ile alınan makineler mevcut ise söz konusu makinelere ait ,Leasing sözleşme sureti</li>
                <li>İşyerine ait Tapu fotokopisi, kiralık ise ( noter onaylı ) kira kontratı fotokopisi,</li>
                <li>Vergi Levhası Fotokopisi, </li>
              </ul>
              <p>Not - 1 TOBB Kapasite Onay Harcı olarak yatırılması gereken, 700.00 TL 'lik tutarın aşağıda belirtilen banka hesap numaralarından herhangi birine yatırılması gerekmektedir. </p>
              <strong>Herhangi bir masraf alınmadan yatırılabileceği banka, şubesi ve hesap numaraları :</strong>
              <p> TR 350004600153888000069072  AKBANK  BAKANLIKLAR ŞBS.</p>
              <p> TR 210001500158007293586413  VAKIFLAR BAN. MERKEZ ŞUBESİ</p>
              <strong>Masraf ödenerek yatırılabileceği banka, şubesi ve hesap numaraları :</strong>
              <p>TR 100006400000142010785529  T.İŞ BANKASI AKAY ŞUBESİ</p>
              <p>TR 020001000760059943505032  ZİRAAT BANKASI AKAY ŞUBESİ</p>
              <p>TR 140001200131100016000038  HALKBANK  ÇUKURAMBAR TİC.ŞB.</p>
              <strong>Not - 2  Kapasite Raporu düzenlenmesi uygun görülmeyen iş yerleri için başvuru talebi ;  </strong>
              <p>Kapasite talep formu ön sayfası ve imza sirküleri fotokopisi ile yapılmaktadır. Başvuru ücreti için Odamız veznesine veya Ardahan Ticaret ve Sanayi Odası adına TR 29 0001 5001 5800 7300 9021 06 Türkiye Vakıfbank T.A.O Ardahan Şubesine 1500,00 TL' lik  yatırılacaktır. (Evrak kontrolünden sonra)</p>
              <p>*** Başvurularınız için sadece Ardahan Ticaret ve Sanayi Odası   Hizmet Binası Kapasite Birimine gelerek yapabilirsiniz.</p>
              <h5>Mevzuat ve Hükümler</h5>
              <div className="sigorta">
                <FontAwesomeIcon icon={faFilePdf} />
                <a className='mevzuat' href="/Kapasite Raporu.pdf">Kapasite Raporu Müracaat Formları</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
