import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function TicariVizeHizmetleri() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Ticari Vize Hizmetleri</title>
            <meta name="description" content="Ticari Vize Hizmetleri" />
            <meta name="keywords" content="Ticari Vize Hizmetleri" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Ticari Vize Hizmetleri</span>
          </div>
          <div className="announcements">
           
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
