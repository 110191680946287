import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function IsMakinesiIslemleri() {
 
  return (
    <div className="container">
      <Helmet>
            <title>İş Makinesi İşlemleri</title>
            <meta name="description" content="İş Makinesi İşlemleri" />
            <meta name="keywords" content="İş Makinesi İşlemleri" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>İş Makinası Tescil İşlemleri</span>
          </div>
          <div className="announcements">
           <strong> İş Makinesinin Tanım ve Kapsamı Nedir?</strong>
           <p>İş makineleri, yol inşaatı ile, tarım, sanayi, bayındırlık, milli savunma hizmetlerinde kullanılan; iş amacına göre üzerine çeşitli ekipmanlar monte edilmiş; karayolunda insan, hayvan, yük taşımasında kullanılamayan motorlu araçlardır</p>
           <strong>İş Makinesini Tescile Yetkili Kuruluşlar Hangileridir?</strong>
           <p>1.Kamu kuruluşlarına ait olanlar ilgili kuruluşlarca,</p>
           <p>2.Özel ve tüzel kişilere ait olanlardan;</p>
           <p>a: Tarım kesiminde kullanılanlar ziraat odalarınca,</p>
           <p>b: Tarım kesiminde kullanılanların dışında kalan ve sanayi, bayındırlık ve diğer Kesimlerde kullanılanların üyesi oldukları ticaret, sanayi veya ticaret ve sanayi odalarınca tescilleri yapılır</p>
           <strong>İş Makinesinin Tescili Neden Zorunludur?</strong>
           <p>2918 sayılı Karayolları Trafik Yasası'nın ilgili Yönetmeliğinin 28. maddesi uyarınca, bütün motorlu araçlar ile ilgili Yönetmelik' te tescili zorunlu kılınan motorsuz araçların sahipleri, araçlarını yetkili kuruluşuna tescil ettirmek ve tescil belgesi almak mecburiyetindedirler</p>
          <strong>İş Makinesinin Satış ve Devirleri Nasıl Yapılır?</strong>
          <p>2918 sayılı Karayolları Trafik Kanunu'nun 36. maddesi uyarınca araçların satış ve devirlerinin noter aracılığıyla yapılması zorunludur. Bunun dışında yapılan her türlü satış ve devirler geçersizdir. Tescilli araçları noter senedi ile satın veya devir alanlar, ilgili tescil kuruluşuna müracaat ederek, bu araçlarını 1 ay içinde tescil ettirmek ve adlarına tescil belgesi almak zorundadırlar.</p>
          <strong>İş Makinesi Tescil Belgesi Düzenleme Evrakları Hangileridir?</strong>
          <p>Tescil belgesi düzenletmek üzere müracaatta bulunan firma veya şahısların müracaat sırasında ibraz etmeleri gereken evraklar:</p>
          <strong>A)İlk defa tescili yapılacak araçlar için;</strong>
          <ul>
            <li>İnebolu Ticaret ve Sanayi Odası'na hitaben yazılmış ve iş makinesinin tescilini talebeden Dilekçe</li>
            <li>İş makinesinin sahiplik belgesi:</li>
          </ul>
          <ol type='a'>
            <li>Fatura (İş makinesi ithal edilmiş ise faturaya ek olarak gümrük giriş beyannamesi ve gümrük vergisinin ödendiğine dair makbuz)</li>
            <li>Veraset ilamı veya mahkemeden alınacak sahiplik belgesi. (a ve b de belirtilen belgelerden herhangi birinin aslı veya noter tasdikli sureti ibraz edilecektir).</li>
          </ol>
          <ul>
            <li>İş Makinesi sicil fişi.</li>
            <li>Teknik belge (Makine Mühendisleri Odası'na kayıtlı bir makine mühendisi tarafından düzenlenmiş ve onaylanmış olacak)</li>
            <li>Varsa resim, broşür, şekil çizim gibi iş makinesine ait fikir verici ek dokümanlar,</li>
          </ul>
          <strong>B) Daha Önce Tescili yapılmış araçlar için;</strong>
          <ul>
            <li>İnebolu Ticaret ve Sanayi Odası'na hitaben yazılmış ve iş makinesinin tescilini talebeden dilekçe</li>
            <li>Noter satış sözleşmesi (Noter tarafından iptal edilen eski iş makinesi tescil belgesi sözleşmeye eklenecektir),</li>
            <li>İş Makinesi sicil fişi.</li>
            <li>Teknik belge (Makine Mühendisleri Odası'na kayıtlı bir makine mühendisi tarafından düzenlenmiş ve onaylanmış olacak</li>
          </ul>
          <strong>Gerekli Belgeler :</strong>
          <strong>Daha Önce Tescil İşlemi Yapılmamış İş Makinaları İçin</strong>
          <ul>
            <li>Antetli kağıda dilekçe</li>
            <li>İthalatçıdan alışlarda gümrük giriş beyannamesi ve fatura aslı, üreticiden alışlarda ise yalnız fatura aslı</li>
            <li>Teknik belge (oda tarafından verilecek veya web sayfasından indirilip eksiksiz olarak doldurulacaktır. Kazıntılı, daksilli ve uygun olmayan formattaki evraklar kabul edilmeyecektir.)</li>
          </ul>
          <strong>Daha Önce Tescil İşlemi Yapılmış İş Makinaları İçin</strong>
          <ul>
            <li>Antetli kağıda dilekçe</li>
            <li>Noter satış senedi</li>
            <li>Teknik Belge (Oda tarafından verilecek veya web sayfasından indirilip eksiksiz olarak doldurulacaktır. Kazıntılı, daksilli ve uygun olmayan formattaki evraklar kabul edilmeyecektir.)</li>
          </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
