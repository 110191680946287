import React from 'react'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

export default function GenelBilgiler() {

  return (
    <div>
      <Helmet>
        <title>Genel Bilgiler</title>
        <meta name="description" content="Genel Bilgiler" />
        <meta name="keywords" content="Genel Bilgiler" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Genel Bilgiler</span>
              </div>
              <div className="announcements">
                <div className="ardahanPhoto" style={{display:'flex', justifyContent:'center'}}>
                  <img src="/ardahanResim.jpg" alt="" />
                </div>
                
                <p>
                  Ardahan İli yaklaşık 3000 yıllık bir geçmişe sahip olup, M.S.628 yılında Hazar Türklerinin bir kolu olan Arda Türklerinin eline geçerek Ardahan adını almıştır. Milattan sonra 1068 yılında Alparslan tarafından fethedilerek Selçukluların egemenliğine geçmiş, 29 Mayıs 1555 tarihinde imzalanan Amasya anlaşması ile Kanuni Sultan Süleyman tarafından Osmanlı İmparatorluğuna dahil edilmiştir.
                </p>
                <p>
                  1876-1877 Osmanlı- Rus savaşı sonunda savaş tazminatı olarak Kars ve Batum' ile birlikte 13 Temmuz 1878 Berlin Antlaşmasıyla Ruslara bırakılan Ardahan 1918 yılında Bresy-Litowsk Anlaşması ile Anavatana kavuşmuş, ancak 30 Ekim 1918 tarihinde Ardahan'da kurulan Milli şura hükümeti tarafından Mondros Mütarekesi şartlan reddedilmiş, Milli şura hükümeti kurtuluş savaşımızla bütünleşerek Kazım Karabekir Paşa ve Halit Paşa komutasındaki ordumuz tarafindan 23 Şubat 1921 tarihinde kurtarılmıştır. Kazım Karabekir Paşa, Tarihe mal olan "Boğazlar Boğazımız,Kars- Ardahan bel kemiğimizdir" sözünü söyleyerek Ardahan'ın yurdumuz için ne kadar stratejik bir öneme sahip olduğunu vurgulamıştır.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
