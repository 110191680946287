import React from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function KulturVeTurizm() {

  return (
    <div>
      <Helmet>
        <title>Kültür Ve Turizm</title>
        <meta name="description" content="Kültür Ve Turizm" />
        <meta name="keywords" content="Kültür Ve Turizm" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Kültür ve Turizm</span>
              </div>
              <div className="announcements">
                <strong>Kültür</strong>
                <p>
                  Asya’dan Anadolu’ya bir geçiş noktası olan Ardahan bu stratejik konumu itibariyle tarih boyunca bu bölgede bulunan devletler arasında mücadelelere sebep olmuş ve sürekli el değiştirmek durumunda kalmıştır. Urartular, Arda Türkleri, Saka- İskit Türleri, Selçuklular ve Osmanlılar yörede yaşamış önemli uygarlıklardır.
                  Ülkemizde her yörenin olduğu gibi Ardahan’ında kendine has özellikleri olan bu özellikleri ile kendini farklı kılan bir kültür mirası bulunmaktadır. Osmanlı İmparatorluğunun son dönemlerinde Rus, Ermeni ve Gürcilerin işgaline uğrayan ve yıllarca esaret altında yaşamak zorunda kalan yöre halkı kendi kültüründen taviz vermemiş, bu mirası günümüze kadar taşımıştır.
                </p>
                <strong>Yöresel Halk Oyunları</strong>
                <p>
                  Ardahan’ın yörelerinde hemen hemen aynı oyunlar oynanır estürüman olarak genellikle davul ve zurna , Kafkas oyunlarında ise Akordeon ve nağara adı verilen davul kullanılır. Yörede genellikle halay ve bar oynanır bu oyunların oynandığı toplumsal alanlar ise düğünler, nişanlar, asker uğurlamaları, üzüntü ve sevinç gibi duyguların ifade edildiği durumlardır. Yöremizde oynanan belli başlı oyunlar ise şunlardır.
                  BAR OYUNLARI: Ağır bar, Sallama, Temur ağa, Nare, Lorke, Şeker oğlan, Sarı seyran, Kaççıke, Tavuk barı, Gazelo, Hoş bilezik,Haran, Döne, Kıskarç, Kürdün kızı, Ardahanın yolları, Hafif bar ve Paşa göçtü.
                  TEK OYUNLAR: Ondört, Şeyh şamil, Ay gızı, Beş açılan, Karabat, Hanım yaylada.
                </p>
                <strong>Yöre Mutfağı (Gastronomi)</strong>
                <p>
                  Ardahan’ın kaşar peyniri ve balı ülke çapında isim yapmıştır. Elma dolması, evelik aşı, pişi, bozbaş, kuymak, ekmek aşı ve helvası en ünlü yemek türleridir.
                </p>
                <strong>Yöresel Yemekler</strong>
                <ul>
                  <li>Un çorbası</li>
                  <li>Kesma Aşı</li>
                  <li>Höre Aşı</li>
                  <li>Keleçoş</li>
                  <li>Cinar çorbası</li>
                  <li>Kelemkeşir çorbası</li>
                  <li>Puşruk Aşı</li>
                  <li>Ayran Çorbası</li>
                  <li>Süt Çorbası</li>
                  <li>Evelik Çorbası</li>
                  <li>Pışırık Aşı</li>
                  <li>İşkembe Çorbası</li>
                </ul>
                <strong>El Sanatları ve Hediyelik Eşya</strong>
                <p>
                  Halıcılık ve gümüş işlemeciliği ildeki en önemli el sanatlarındandır. Yöre motiflerini taşıyan gümüş kemer, başlık ve takılar yöreye gelen turistlerin ilgisini çeken hediyelik eşyalardır.
                </p>
                <strong>Yöremizde El Sanatları</strong>
                <ul>
                  <li>Halı</li>
                  <li>Kilim  </li>
                  <li>Ğurcun (heybe)</li>
                  <li>Yöresel folklorik bebek giysisi</li>
                  <li>Yolluk</li>
                  <li>Göğüslük</li>
                  <li>Hasır</li>
                  <li>Keçe</li>
                  <li>Yün Çorap</li>
                  <li>Patik</li>
                  <li>Yörsel Dantel</li>
                  <li>Yün İplik</li>
                  <li>Kayış</li>
                  <li>At Koşun Araçları</li>
                </ul>
                <strong>Halı Desenleri</strong>
                <ul>
                  <li>Derme</li>
                  <li>Kafkas</li>
                  <li>Adiler</li>
                  <li>Lezgi kazağı</li>
                  <li>Koç boynuzu</li>
                </ul>
                <p>Kilimlerde bir çok desen kendi özelliğini tek bir kilimde yansıtır.</p>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
