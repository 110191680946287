import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function IgemeBirimi() {
 
  return (
    <div className="container">
      <Helmet>
            <title>İGEME Birimi</title>
            <meta name="description" content="İGEME Birimi" />
            <meta name="keywords" content="İGEME Birimi" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>İhracatı Geliştirme Etüd Merkezi (İGEME) İl Temsilciliği</span>
          </div>
          <div className="announcements">
            <p>Temsilciliğin amacı, İhracatçı firmalarımızın dış pazarlardaki pazar paylarının artırılması, yeni pazarlar bulunması, ihraç ürünlerinin çeşitlendirilmesi, ülkemizdeki ve dünyadaki ekonomik gelişmeler ve ticari prosedürler konusunda ihracatçılarımızın bilgilendirilmesi, ihracata yeni başlayacak firmalara gerekli bilgilerin sağlanması amaçlarına yönelik çalışmalar yapmakta olan İGEME’nin, bu konulardaki faaliyetlerinin KOBİ’lere daha etkin bir şekilde ulaştırılmasını sağlamaktır.</p>
            <p>Bu kapsamda gerçekleştirilen eğitim ve seminerler ile KOBİ’lerin bilgi düzeyleri artırılmakta, İGEME’nin AR-GE çalışmalarının yer aldığı ülke/sektör/ürün “Araştırma Raporları”, ihracatçılara yönelik pratik bilgilerin yer aldığı “Pratik Bilgiler Serisi” ve devlet yardımları konusundaki dokümanlarından faydalanılarak KOBİ’lerin ihtiyaç duyduğu bilgi kaynaklarına ilk elden kavuşmalarına imkan sağlanmaya çalışılmaktadır.</p>
            <p>Faaliyetlerine başladığı günden bugüne kadar verdiği “Uluslar arası Pazarlama”, “Dış Ticarette Ödeme ve Teslim Şekilleri”, Dış Ticarette Anlaşmazlıkların Çözümü”, “Elektronik Ticaret”, “İhracata Hazırlık” gibi eğitimlerle ilimizde dış ticaretin gelişimine oldukça katkıda bulunmuş olup, Avrupa, Ortadoğu ve Afrika ülkelerinden gelen yabancı işadamı heyetleri ile yapılan ikili iş görüşmelerinden çok sayıda firmamız istifade etmiştir. </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
