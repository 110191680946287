import React from 'react';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function genelSekreterlik() {
  return (
    <div>
      <Helmet>
        <title>Genel Sekreterlik</title>
        <meta name="description" content="Genel Sekreterlik" />
        <meta name="keywords" content="Genel Sekreterlik" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Genel Sekreterlik</span>
              </div>
              <div className="announcements">
                <div className="container">
                  <div className="row" style={{justifyContent:'center'}}>
                    <div className="col-sm-12 col-lg-12">
                      <div className="row" style={{justifyContent:'center'}}>
                        <div className="col-12" style={{display:'flex', justifyContent:'center'}}>


                          <div class="card" style={{ width: '18rem' }}>
                            <img src="/asinKizilkaya.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                              <h5 class="card-title">Aşkın Kızılkaya</h5>
                              <span>Genel Sekreter</span>
                            </div>
                          </div>


                        </div>

                        <div className="row cardContainer" >



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/ayferKazma.png" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Ayfer Kazma</h5>
                                <span>Sekreterya</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/asinKizilkaya.png" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Aşkın Kızılkaya</h5>
                                <span>Ticaret Sicil Müdürü Vekili</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="/talipBekmez.png" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Talip Bekmez</h5>
                                <span>Dış Ticaret Memuru</span>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className="row cardContainer">



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Oğuzhan COŞKUN</h5>
                                <span>Geçiş Belgesi Dağıtım Sorumlusu</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Ferhat Buğra BAVAŞ</h5>
                                <span>Geçiş Belgesi Dağıtım Sorumlusu</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Eren ŞİRİN</h5>
                                <span>Geçiş Belgesi Dağıtım Sorumlusu</span>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className="row cardContainer">



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Seçil Dilara ATALAY</h5>
                                <span>Geçiş Belgesi Dağıtım Sorumlusu</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Taha Göktuğ BAŞKENT</h5>
                                <span>Geçiş Belgesi Dağıtım Sorumlusu</span>
                              </div>
                            </div>
                          </div>



                          <div className="col-4">
                            <div class="card" style={{ width: '18rem' }}>
                              <img src="ardahantso-vektor3.svg" class="card-img-top" alt="..." />
                              <div class="card-body">
                                <h5 class="card-title">Ümit YILDIZ</h5>
                                <span>Geçiş Belgesi Dağıtım Sorumlusu</span>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
