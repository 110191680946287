import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function TseTemsilcilik() {
  
  return (
    <div className="container">
      <Helmet>
            <title>TSE Temsilciliği</title>
            <meta name="description" content="TSE Temsilciliği" />
            <meta name="keywords" content="TSE Temsilciliği" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>TSE Mahali Temsilciliği Genel İşlemleri</span>
          </div>
          <div className="announcements">
            <p>Odamıza kayıtlı üyelerinizin TSE ile olan iş ve işlemlerine yardımcı olmak bilgi ve belge sağlamak.</p>
            <p>Araç Tadil Projelerin onaylaması, dosyalanması ve raporlanmasını sağlamak.</p>
            <p>TSE’li ürünler hakkında yapılan tüketici şikayetlerini ilgili birimlere iletmek.  </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
