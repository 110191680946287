import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Heading,
  useToast,
} from '@chakra-ui/react';


export default function Iletisim() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [aciklama, setAciklama] = useState('');
  
  const toast = useToast(); 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const firmaData = {
      name,
      surname,
      phone,
      email,
      aciklama,
    };
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_STRING;
    
    try {
      const response = await Axios.post(
        `${apiUrl}/sendContactInfo`,
        firmaData,
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      toast({
        title: 'Form Başarıyla Gönderildi.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      console.log(response.data);
    } catch (error) {
      toast({
        title: 'Form Gönderilirken Hata Oluştu.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Hata Oluştu', error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>İletişim</title>
        <meta name="description" content="İletişim" />
        <meta name="keywords" content="İletişim" />
      </Helmet>
      <Box className="container" maxW="container.md" mt={8}>
        <Heading size="lg" mb={4}>
          <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: '1rem', marginRight: '5px' }} />
          İletişim
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="name" isRequired>
              <FormLabel>Adı:</FormLabel>
              <Input
                type="text"
                placeholder="Adınızı girin"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl id="surname" isRequired>
              <FormLabel>Soyadı:</FormLabel>
              <Input
                type="text"
                placeholder="Soyadınızı girin"
                onChange={(e) => setSurname(e.target.value)}
              />
            </FormControl>

            <FormControl id="phone" isRequired>
              <FormLabel>Telefonu:</FormLabel>
              <Input
                type="tel"
                placeholder="Telefon numaranızı girin"
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormControl>

            <FormControl id="email" isRequired>
              <FormLabel>E-Posta:</FormLabel>
              <Input
                type="email"
                placeholder="E-posta adresinizi girin"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            <FormControl id="aciklama">
              <FormLabel>Açıklama:</FormLabel>
              <Textarea
                placeholder="Mesajınızı buraya yazın"
                onChange={(e) => setAciklama(e.target.value)}
              />
            </FormControl>

            <Button colorScheme="blue" type="submit" width="full">
              Gönder
            </Button>
          </VStack>
        </form>
      </Box>
    </div>
  );
}
