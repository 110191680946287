import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from 'react-helmet'
export default function TpadkBirimi() {

  return (
    <div className="container">
      <Helmet>
            <title>TPADK Birimi</title>
            <meta name="description" content="TPADK Birimi" />
            <meta name="keywords" content="TPADK Birimi" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>TAPDK (Alkol ve Tütün Ruhsatları) Birimi Genel İşlemleri</span>
            </div>
            <div className="announcements">
              <p>Tütün ve Alkol Piyasası Düzenleme Kurumu, 4733 sayılı Kanun ile 15.04.2008 tarih ve 26848 sayılı Resmi Gazete’de yayımlanan 4733 sayılı Kanunda değişiklik yapılması hakkındaki 5752 sayılı Kanun ve diğer Kanunlarla Kuruma verilen görevleri yerine getirmek ve yetkileri kullanmak üzere kamu tüzel kişiliğini haiz, idari ve mali özerkliğe sahip olarak kurulmuştur.Yozgat Ticaret ve Sanayi Odası, Türkiye Odalar ve Borsalar Birliği ve Tütün ve Alkol Piyasası Düzenleme Kurumu arasında yapılan protokol neticesinde yetkilendirilmiştir. Odamız bu çerçevede Yozgat merkezi bulunan bakkal, market, süpermarket, restaurant, lokanta, tekel bayisi, gece kulübü ve benzeri yerlerin taleplerini karşılamak amacıyla TAPDK Birimi'ni oluşturmuştur.</p>
              <h5>DÜZENLENMEMİŞ ALAN-AVRUPA BİRLİĞİ BİLDİRİM SİSTEMİ</h5>
              <strong>Düzenlenmemiş Alan Hakkında Genel Bilgi</strong>
              <p>Düzenlenmemiş alan, Avrupa Birliği’nin (AB) ortak mevzuatının bulunmadığı, dolayısıyla AB üyesi ülkelerin kendi düzenlemelerini yapabilecekleri alanı ifade etmektedir. Bu alan, hakkında hiçbir AB mevzuatı olmayan bir ürünü ilgilendirebileceği gibi, hakkında AB mevzuatı bulunan bir ürüne dair ortak düzenleme kapsamına alınmamış bir hususu da işaret edebilmektedir.</p>
              <strong>Düzenlenmemiş Alan ve Türkiye</strong>
              <p>Türkiye ile AB arasındaki Gümrük Birliği tesis eden 1/95 sayılı Ortaklık Konseyi Kararı ile 2/97 sayılı Ortaklık Konseyi Kararı kapsamında, AB ile Türkiye’nin düzenlenmemiş alanda karşılıklı tanıma yükümlülüğü bulunmaktadır. Avrupa Komisyonu AB Resmi Gazetesi’nde 4 Kasım 2003 tarihinde yayımladığı 2003/C 265/02 sayılı Bildirgesi ile düzenlenmemiş alanda Türk ürünlerinin de üye devlet kaynaklı ürünlerle aynı muameleye tabi tutulması gerektiğini üye devletlere hatırlatmış, Türkiye’de üretilmiş veya üçüncü ülke menşeli olsa dahi Türkiye’de serbest dolaşıma girmiş ürünlerin Topluluk gümrük kapılarında ilke olarak denetime tabi tutulmamalarını talep etmiş ve Topluluk pazarına nasıl arz edileceğinin koşullarını düzenlemiştir.

                AB Komisyonu ayrıca, üye ülkelerden düzenlenmemiş alanda yapacakları teknik düzenlemelere Türk mallarının tanınması koşulunu derc etmesini istemekte, Türk ürünü ithal edenlerin bu ürünleri piyasaya arz etmelerinin engellenmesi halinde ulusal mahkemelere Topluluk Kurucu Anlaşması’nın 28-30 uncu maddelerinin ihlali ve komisyonun bu Tebliği’nin uygulanması gerekçesiyle başvurabileceklerini belirtmekte, buna aykırı hareket eden üye ülkelerin ise Topluluk kurallarını ihlal edecekleri uyarısında bulunmaktadır.

                Bu yükümlülük çerçevesinde, Avrupa Komisyonu, düzenlenmemiş alanda Türkiye menşeli ürünlerin AB üyesi ülke menşeli ürünlerle aynı muameleye tabi tutulması gerektiğini bildirerek; Türkiye’de üretilmiş veya üçüncü ülke menşeli olsa dahi Türkiye’de serbest dolaşıma girmiş ürünlerin ilke olarak denetime tabi tutulmamaları gerektiğini belirtmiştir.

                Buna göre, varış ülkesinin insan sağlığını düzenleyen kurallarına eşdeğer koruma sağlayan düzenlenmemiş alandaki Türk ürünlerinin Topluluk pazarlarına arzı ancak bir takım koşulların varlığı halinde engellenebilmektedir. Üye ülke, piyasaya arzını kısıtlama gerekçelerini bilimsel anlamda ve Topluluk kurallarının izin verdiği meşru gerekçelerle kanıtlamak ve bu durumu ürünün ithalatçısına ve dağıtıcısına haber verip, görüşlerini almak durumundadır.</p>
              <strong>AB Bildirim Sistemi</strong>
              <p>Düzenlenmemiş alanda Türkiye ile AB arasında yukarıda belirtilen “karşılıklı tanıma” prensibi söz konusu olduğundan; bu alandaki bildirim sisteminin sektör tarafından takip edilmesinin faydalı olacağı düşünülmüştür.

                Avrupa Komisyonu tarafından anılan Dış Ticaret Müsteşarlığı’na iletilen AB üye ülkelerinin “düzenlenmemiş alan”da yaptıkları mevzuat taslaklarına ilişkin “mevzuat düzenlemeleri bildirim listesi”, Kurumumuz görev alanına giren konu başlıkları itibariyle Kurumumuza düzenli olarak iletilmektedir.

                Kurumumuza gönderilen, düzenlenmemiş alanda AB üye ülkelerinin taslak mevzuatlarının bildirildiği “mevzuat düzenlemeleri bildirim listesi”nde yer alan “Bildirim Mesajları” ile “Bildirimlerin Taslak Metinleri”nin Kurumumuzca ilgili sektöre iletilmesi ve sektörden gelecek olası yorumların da Kurumumuzca değerlendirilmesini müteakip, Dış Ticaret Müsteşarlığı’na iletilmesi amacıyla bu internet sayfası oluşturulmuştur.

                Aşağıdaki “Güncel AB Bildirimleri” Tablosunda yer alan her bir ülke bildiriminin numarasının üzeri tıklandığında o bildirime ilişkin sayfa açılmaktadır. Açılan bu yeni sayfadan söz konusu bildirimi ve bildirime konu teknik mevzuatın taslak metnini indirebilir, “Yorumla” kutucuğunu doldurduktan sonra kutucuk altında yer alan “Gönder” butonunu tıklayarak işbu bildirime yapmış olduğunuz yorumu Kurumumuza iletebilirsiniz.

                Bir taslak mevzuatta AB üyesi ülkelere ve/veya Avrupa Ekonomik Alanına taraf EFTA ülkelerine atıf yapan bir karşılıklı tanıma hükmü mevcutken, Türkiye’nin anılan hüküm dışında bırakılmış olduğunun tespit edilmesi halinde, bu tespitin Kurumumuza bildirilmesi önem taşımaktadır.</p>
              <h5>İlgili Mevzuat ve Kanunlar</h5>
              <div className="sigorta">
                <FontAwesomeIcon icon={faFilePdf} />
                <a className='mevzuat' href="/tpdk.pdf"> 4733 Sayılı TÜTÜN VE ALKOL PİYASASI DÜZENLEME KURUMU TEŞKİLAT VE GÖREVLERİ HAKKINDA KANUN</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
