import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function TicaretSicilMudurlugu() {
 
  return (
    <div className="container">
      <Helmet>
            <title>Ticaret Sicil Müdürlüğü</title>
            <meta name="description" content="Ticaret Sicil Müdürlüğü" />
            <meta name="keywords" content="Ticaret Sicil Müdürlüğü" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Ticaret Sicil Müdürlüğü Genel İşlemleri</span>
            </div>
            <div className="announcements">
              <p>Sicil Müdürlüğünün görevi; 6762 sayılı Türk Ticaret Kanunu, 5174 sayılı Odalar Kanunu ile ilgili tüzük ve yönetmelik hükümleri gereğince gerçek ve tüzel kişi tacirlerin Ticaret Siciline tescil, ilan, Oda kayıt işlemlerini yapmak, oluşturulan sicil dosyalarını saklamak ve bilgisayar ortamına aktarmak, 1479 sayılı Bağ-Kur Kanunu’nun yüklediği görevleri yerine getirmek, mevzuat uyarınca istenen belge ve bilgileri sicil kayıtlarına göre vermek şeklindedir.

                Ticari işletmelerin kuruluşları için Türk Ticaret Kanunu’nun zorunlu kıldığı tescil, iş ve işlemlerini yerine getirmek.

                İşletmelerin ticaret sicil dosyalarını arşivlemek ve her an kullanıma hazır tutmak.

                İşletmelerin tescil edilmiş bulunan hususlarında meydana gelen tadilleri tescil etmek.

                Çeşitli nedenlerle yaşamı sona eren işletmelerin sicil kayıtlarının terkinini sağlamak.

                Yasalarda tanımlanan ticaret sicil defterlerini tutmak, tescili gerektiği halde başvuru yapılmayan işletmelerin re’sen tescil işlemlerini yapmak.

                Kişilerin ve kuruluşların sicil dosyalarına ilişkin bilgi ve belge taleplerini karşılamak.

                Ticaret sicil iş ve işlemlerini hızlı ve verimli yürütebilmek için gerekli tedbirleri almak.

                Ticaret siciline ilişkin görüş ve öneri taleplerini hazırlamak.

              </p>
              <strong>Sicilde İstenilen Evraklar</strong>
              <span>Gerçek Kişiler:</span>
              <ol>
                <li>İki adet İmza Beyannamesi</li>
                <li>Vergi Levhası fotokopisi</li>
                <li>Kira Kontratı veya Tapu Fotokopisi</li>
                <li>Tutulan Ticari defterin Noter Tasdik kısmının fotokopisi</li>
                <li>İkametgah İlmühaberi (Resimli, muhtardan ya da noterden)</li>
                <li>Nufüs Kağıdı örneği (Resimli, muhtardan ya da noterden)</li>
                <li>Taahhütname (Oda tarafından verilen)</li>
                <li>Odadan verilen Matbu Dilekçe</li>
              </ol>
              <span>Tüzel Kişiler:</span>
              <ol>
                <li>Noterden ve Sanayi Ve Ticaret İl Müdürlüğünden onaylı en az 4 adet şirket esas mukavelesi</li>
                <li>Sermayenin binde ikisinin Merkez Bankası tüketiciyi koruma fonuna yattığına ilişkin makbuz</li>
                <li>Şirket yetkilisinin İmza Beyannamesi</li>
                <li>Şirket ortaklarının Nüfus Kağıt örnekleri (resimli, muhtardan ya da noterden)</li>
                <li>Şirket ortaklarının İkametgah örnekleri (resimli, muhtardan ya da noterden)</li>
                <li>Taahhütname (oda tarafından verilen)</li>
                <li>Odadan verilen Matbu Dilekçe</li>
              </ol>
              <span>ODAYA KAYIT İÇİN GEREKLİ EVRAKLAR </span>
              <ol>
                <li>Sicil Tasdiknamesi (aslı)</li>
                <li>İlan Gazetesi (aslı)</li>
                <li>İmza Sirküleri (aslı)</li>
                <li>Nüfus Kayıt Örneği (aslı, resimli)</li>
                <li>İkametgah İlmühaberi (Resimli, muhtardan ya da noterden)</li>
                <li>İki (2) adet fotoğraf</li>
              </ol>
              <span>Tüzel Kişiler:</span>
              <ol>
                <li>Ticaret Sicil Tasdiknamesi (aslı)</li>
                <li>İlan Gazetesi (aslı)</li>
                <li>İmza Sirküleri (aslı)</li>
                <li>Ana Sözleşme (aslı)</li>
                <li>Şubeler için kuruluş merkez gazetesi</li>
                <li>Şirket ortakları için nüfus cüzdanı sureti</li>
                <li>Şirket ortakları için ikametgah ilmühaberi </li>
              </ol>
              <strong>Sicil Memurluğu Hizmetleri</strong>
              <p>Ticaret Sicili İşlemleri ile İlgili Açıklama ve Tanımlar
                Ticaret Sicil Memurluklarının Ticaret ve Sanayi Odalarına Devri
                27.06.1995 tarihli resmi gazetede yayınlanarak yürürlüğe giren 559 sayılı TTK'nun bazı maddelerinde değişiklik yapılmasına dair Kanun Hükmünde Kararname ile TTK'nun bazı maddeleri ile Ticaret Siciline ilişkin 26,27,28. maddeleri değiştirilerek, daha önce Adalet Bakanlığına bağlı olarak Asliye Hukuk Mahkemeleri bünyesinde kurulan Ticaret Sicili Memurlukları, Sanayi ve Ticaret Bakanlığına bağlı olarak Ticaret ve Sanayi Odaları nezdinde kurulmaları kararlaştırılmıştır.
                Bu sebeple, 31.10.1995 yılında Adalet Bakanlığı, Sanayi ve Ticaret Bakanlığı ve TOBB tarafından imzalanan protokol gereğince, Ticaret Sicili Memurluklarının Odamıza devri gerçekleşmiştir.
              </p>
              <span>Ticaret Sicili Memurluğu'nun İş ve Hizmetleri</span>
              <ol>
                <li>Tacir niteliğinde bulunan gerçek ve tüzel kişilerin ticaret siciline tescillerini yapmak,</li>
                <li>Gerçek ve tüzel kişi tacirlerin durumlarında meydana gelen değişiklikleri yapmak,</li>
                <li>Gerçek kişilerde unvan, adres, faaliyet konusu değişikliği, ticareti terk ,v.b.işlemleri yapmak,</li>
                <li>Tüzel kişilerde ana sözleşme değişikliği , tescile tabi yönetim kurulu,ortaklar kurulu, genel kurullar, fesih ve tasviyeler vb)</li>
                <li>Ticaret Sicili memurluğunca düzenlenmesi ön görülen belgeleri vermek,</li>
                <li>Ticari işletme rehni tescil etmek,</li>
                <li>Kamu kurum ve kuruluşlarının müseccel tacirler hakkında istedikleri bilgi ve belgeleri vermek,</li>
                <li>Mevzuat gereğince sicil memurluğunca yapılması öngörülen diğer iş hizmetleri yapmak,</li>
              </ol>
              <span>Ticaret Sicili İşlemlerinin Tabi Olduğu Hükümler</span>
              <p>Ticaret Siciline ait bütün işlem ve işler özellikle tescil, değişiklik ve kayıt silmeler Türk Ticaret Kanunu ile diğer kanunlar ve Ticaret Sicili Tüzüğü hükümlerine göre yapılır.
                Herhangi bir olayın tescil edilebilmesi için bu hususun Kanun veya Ticaret Sicili Tüzüğü'nde yer almış olması zorunludur. (TST.9_12_27)

                Harç Zorunluluğu
                Ticaret Sicili Memurluğundan herhangi bir istekte bulunan veya örnek bir evrak isteyen kimse kanuni harcı ödemediği sürece isteği ile ilgili hiçbir işlem yapılamaz. (TST. 12) Her yıl bu harç tarifesi ocak ayında değişmektedir.
                Tescil, Koşulları, İlgililer, Talebin Şekli, Süresi, Değişiklikler
              </p>
              <span>Tescil, Tadil, Terkin:</span>
              <p>Tescil: Bir vakıanın (olayın) sicile geçirilmesi
                Tadil (Değişiklik): Sicile yazılmış bir vakıadaki değişiklik dolayısıyla sicildeki kayıtların değiştirilmesi ve düzeltilmesi.
                Terkin (Kayıt Silme): Sicile yazılmış bir vakıanın ortadan kalkması veya sona ermesi nedeniyle ona ait kayıtların silinmesidir.
                Tescilin Koşulları: Tescil talep üzerine yapılır.Tescil talebinin ilgililer veya mümessilleri veya hukuki halefleri tarafından dilekçe ile kanunda aksine hüküm olmadıkça 15 günlük süre içerisinde yapılması gerekmektedir.
                İlgililer: Tacirin gerçek kişi olması halinde kendisi, tüzel kişi olması halinde yetkili organ veya yetkili temsilcileridir.
                Bir hususun tescilini istemeye birden fazla yetkili olduğu zaman kanunda aksine hüküm olmadıkça bunlardan birinin isteği yeterli sayılır.
                Değişiklikler: Tescil edilmiş hususlarda meydana gelen her türlü değişikliğin tescili zorunludur. (T.K. 29. 30. 31. 32. 33) (TST. 21. 24. 31. 32)

                Sicil Memurunun İnceleme Mükellefiyeti, Tescilin Reddi, İtiraz
                Sicil Memurunun İnceleme Mükellefiyeti:
                Sicil memuru tescil için gerekli yasal koşulların var olup olmadığını incelemekle yükümlüdür.
                Tescil için kanun ve Ticaret Sicili Tüzüğü'nde öngörülen koşulların yerine getirilip getirilmediğini araştırır.
                Tescili istenen olayların 3. kişilerde yanlış düşünce uyandırıp uyandırmayacağını, gerçeğe, kamu düzenine, kanunun emredici hükümlerine uygunluğunu araştırmakla mükelleftir. (TK. 34) (TST 28. 29. 30)  </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
