import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function InsanKaynakları() {
  
  return (
    <div className="container">
      <Helmet>
            <title>İnsan Kaynakları</title>
            <meta name="description" content="İnsan Kaynakları" />
            <meta name="keywords" content="İnsan Kaynakları" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>İnsan Kaynakları Birimi Genel Hizmetleri</span>
            </div>
            <div className="announcements">
              <p>Ardahan Ticaret ve Sanayi Odası, her zaman yaptığı yeniliklerle öncü olmuş bir sivil toplum kuruluşudur. Odamız üyeleri zaman zaman çeşitli iş konularında kendine güvenen, başarılı, hem ruhsal hem de zihinsel gelişimini tamamlamış bireylerin iş gücüne her zaman için ihtiyaç duymaktadır. Ardahan  Ticaret ve Sanayi Odası, üyeleri ile çalışmak isteyen bireylerin aşağıdaki formda verilen boşlukları eksiksiz ve doğru olarak doldurması gerekmektedir. Bu formu doldurduktan sonra ise "Bilgilerimi Kaydet" butonuna basarak göndermeniz gerekmektedir. Bilgileriniz İnsan Kaynakları Departmanımıza ulaştığında ise mesleğinizle ilgili Odamız üyesi firmalarda ihtiyaç varsa, ileriki zamanlar olacağı düşünülen pozisyonlarda göz önünde bulundurularak bize verdiğiniz iletişim bilgilerinden iletilecektir. Eksik ya da hatalı doldurulan formlar geçersiz sayılacaktır. </p>

              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Form</span>
              </div>

              <form action="" method="POST">
                <div>
                  <label htmlFor="name">Adı:</label>
                  <input type="text" name='name' />
                </div>
                <div>
                  <label htmlFor="surname">Soyadı:</label>
                  <input type="text" name='surname' />
                </div>
                <div>
                  <label htmlFor="phone">Cep Telefonu:</label>
                  <input type="text" name='phone' />
                </div>
                <div>
                  <label htmlFor="email">E-Posta:</label>
                  <input type="text" name='email' />
                </div>
                <div>
                  <label htmlFor="description">Açıklama:</label>
                  <textarea name="description" id="description"></textarea>
                </div>
                <input type="submit" />
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
