import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

export default function EskiBaskanlarimiz() {
 
  return (
    <div className="container">
        <Helmet>
            <title>Eski Başkanlarımız</title>
            <meta name="description" content="Eski Başkanlarımız" />
            <meta name="keywords" content="Eski Başkanlarımız" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Eski Başkanlarımız</span>
            </div>
            <div className="announcements">
              <ol>
                <li>25.10.1946 - 12.08.1951 HALİL BAYKAL</li>
                <li>13.08.1951 - 14.06.1953 MUZAFFER ÇAKIR</li>
                <li>15.06.1953 - 05.01.1962 ZİKRİ YILMAZ</li>
                <li>06.01.1962 - 14.01.1964 OSMAN GÜNGÖR</li>
                <li>15.01.1964 - 21.12.1969 SÜREYYA ÇANAKÇI</li>
                <li>22.12.1969 - 19.12.1975 ZİKRİ YILMAZ</li>
                <li>20.12.1975 - 05.12.1978 FERAMUZ ÇAĞLAYAN</li>
                <li>06.12.1978 - 30.11.1981 VEYSEL BULUT</li>
                <li>01.01.1981 - 18.04.1984 ERDAL USLU</li>
                <li>27.04.1984 - 09.12.1986 KAZIM YILMAZ</li>
                <li>10.12.1986 - 10.04.1989 NİZAMETTİN UĞURLU</li>
                <li>10.04.1989 - 09.04.1992 İSMET AVŞAR</li>
                <li>10.04.1992 - 19.03.2005 LATİFŞAH SURAL</li>
                <li>20.03.2005 - 12.05.2013 LATİF TOSUNOĞLU</li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
