import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function MevzuatKanunlar() {
  
  return (
    <div className="container">
      <Helmet>
            <title>Mevzuat ve Kanunlar</title>
            <meta name="description" content="Mevzuat ve Kanunlar" />
            <meta name="keywords" content="Mevzuat ve Kanunlar" />
        </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="announcementsContainer">
            <div className="innerAnnouncements">
              <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
              <span>Mevzuat ve Kanunlar</span>
            </div>
            <div className="announcements">
              <div className="sigorta">
                <FontAwesomeIcon icon={faFilePdf} />
                <a className='mevzuat' href="/MevzuatMetni.pdf">SİGORTACILIK KANUNU</a>
              </div>

              <div className="sigorta">
                <FontAwesomeIcon icon={faFilePdf} />
                <a className='mevzuat' href="/TutunVeAlkolMevzuat.pdf"> 4733 Sayılı TÜTÜN VE ALKOL PİYASASI DÜZENLEME KURUMU TEŞKİLAT VE GÖREVLERİ HAKKINDA KANUN</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
