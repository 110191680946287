import React from 'react'
import { Helmet } from 'react-helmet';
export default function RaporYayin() {
  
  return (
    <div>
      <Helmet>
            <title>Rapor - Yayın</title>
            <meta name="description" content="Rapor - Yayın" />
            <meta name="keywords" content="Rapor - Yayın" />
        </Helmet>
      raporYayin

    </div>
  )
}
