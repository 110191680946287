import React from 'react';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function tarimsalUrunler() {
  return (
    <div>
      <Helmet>
        <title>Tarımsal Ürünler</title>
        <meta name="description" content="Tarımsal Ürünler" />
        <meta name="keywords" content="Tarımsal Ürünler" />
      </Helmet>


      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Tarımsal Ürünler</span>
              </div>
              <div className="announcements">
                <strong>TARIMDA </strong>
                <p>
                  Tek hasatlık mevsim koşullarında  yapılan tarımda verimin yeterli olmadığı gibi gübrelemeninse  toprak tahliline göre verilmemiş olmasına  bağlı olarak verim iyiden iyiye düşmektedir…
                  Geçim kaynağımız için üretilen başlıca  ürünlerimiz
                </p>
                <span>Arpa:</span>
                <p>
                  Hayvan yem  yapımında ve  kümes hayvanlarımızın beslenmesin de kullanırız. Arpadan yarma yaparak yemeklerde de kullanırız Zaman zaman  ihtiyaç fazlalığı satılarak aile bütçesine katkı sağlanılır…
                </p>
                <div className="tarimsalContainer">
                  <img src="/arpa.jpg" alt="" />
                </div>

                <span>Buğday:</span>
                <p>
                  Un  yapımında kullanılmak için üretilen buğday  , zaman zaman bulgur yapımı içinde kullanırız…
                </p>
                <div className="tarimsalContainer">
                  <img src="/bugday.jpg" alt="" />
                </div>
                <span>Kurul`( fiğ) :</span>
                <p>
                  Tarlaların bir sonraki ekim döneminde toprağın  nadasa bırakılmış ve dinlendirilmiş gibi toprağı bir sonraki yılda verimli hale getirildiği düşünüldüğü için  sıkça ekilir  ve ürününden hayvan beslenmesin de yem olarak kullanılmakta. İhtiyaç fazlası ürün satılarak aile bütçesine katkıda bulunulur  bu ürünümüzün Pazar hacmi geniş olup rahatlıkla satıldığı bilinmektedir…
                </p>
                <span>Çavdar:</span>
                <p>Genel amaçlı ekilen bu ürünümüz den   , veriminin fazlalığından dolayı genelde fazla saman elde etmek için üretildiği bilinmektedir…</p>
                <strong>Not:</strong>
                <span>4(dört) tarım ürünümüzün karıştırılarak yem yapımında kullanılmasın da  hayvan beslenmesine  ve süt üretimine ciddi  artışlar görüldüğü bilinmekte…</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
