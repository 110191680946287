import React, { useState } from 'react';
import Axios from 'axios';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  RadioGroup,
  Radio,
  Stack,
  Container,
  useToast,
} from '@chakra-ui/react';

export default function IthaltciFirmaEkle() {
  const [uyeSicil, setUyeSicil] = useState('');
  const [unvan, setUnvan] = useState('');
  const [adresTip, setAdresTip] = useState('');
  const [adres, setAdres] = useState('');
  const [isTelefon, setIsTelefon] = useState('');
  const [uyeKayitTarihi, setUyeKayitTarihi] = useState('');
  const [naceKodu, setNaceKodu] = useState('');
  const [naceAdi, setNaceAdi] = useState('');
  const [durumu, setDurumu] = useState('');
  const apiUrl = process.env.REACT_APP_API_STRING;
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const firmaData = {
      uyeSicil,
      unvan,
      adresTip,
      adres,
      isTelefon,
      uyeKayitTarihi,
      naceKodu,
      naceAdi,
      durumu,
    };

    const token = localStorage.getItem('token');

    try {
      const response = await Axios.post(`${apiUrl}/addImporterCompany`, firmaData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast({
        title: 'Başarılı.',
        description: 'Firma başarıyla eklendi.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Hata.',
        description: 'Firma eklenirken bir hata oluştu.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Hata Oluştu', error);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <FormControl id="uyeSicil" isRequired>
          <FormLabel>Üye Sicil No</FormLabel>
          <Input
            type="number"
            value={uyeSicil}
            onChange={(e) => setUyeSicil(e.target.value)}
            placeholder="Üye Sicil No"
          />
        </FormControl>

        <FormControl id="unvan" isRequired mt={4}>
          <FormLabel>Unvan</FormLabel>
          <Input
            type="text"
            value={unvan}
            onChange={(e) => setUnvan(e.target.value)}
            placeholder="Unvan"
          />
        </FormControl>

        <FormControl id="adresTip" mt={4}>
          <FormLabel>Adres Tipi</FormLabel>
          <Input
            type="text"
            value={adresTip}
            onChange={(e) => setAdresTip(e.target.value)}
            placeholder="Adres Tipi"
          />
        </FormControl>

        <FormControl id="adres" isRequired mt={4}>
          <FormLabel>Adres</FormLabel>
          <Input
            type="text"
            value={adres}
            onChange={(e) => setAdres(e.target.value)}
            placeholder="Adres"
          />
        </FormControl>

        <FormControl id="isTelefon" mt={4}>
          <FormLabel>İş Telefonu</FormLabel>
          <Input
            type="tel"
            value={isTelefon}
            onChange={(e) => setIsTelefon(e.target.value)}
            placeholder="İş Telefonu"
          />
        </FormControl>

        <FormControl id="uyeKayitTarihi" isRequired mt={4}>
          <FormLabel>Üye Kayıt Tarihi</FormLabel>
          <Input
            type="date"
            value={uyeKayitTarihi}
            onChange={(e) => setUyeKayitTarihi(e.target.value)}
          />
        </FormControl>

        <FormControl id="naceKodu" isRequired mt={4}>
          <FormLabel>Nace Faaliyet Kodu</FormLabel>
          <Input
            type="text"
            value={naceKodu}
            onChange={(e) => setNaceKodu(e.target.value)}
            placeholder="Nace Faaliyet Kodu"
          />
        </FormControl>

        <FormControl id="naceAdi" isRequired mt={4}>
          <FormLabel>Nace Faaliyet Adı</FormLabel>
          <Input
            type="text"
            value={naceAdi}
            onChange={(e) => setNaceAdi(e.target.value)}
            placeholder="Nace Faaliyet Adı"
          />
        </FormControl>

        <FormControl as="fieldset" mt={4}>
          <FormLabel as="legend">Durumu</FormLabel>
          <RadioGroup onChange={setDurumu} value={durumu}>
            <Stack direction="row">
              <Radio value="İthalatçı">İthalatçı</Radio>
              <Radio value="İhracatçı">İhracatçı</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        <Button type="submit" colorScheme="blue" mt={4}>
          Ekle
        </Button>
      </form>
    </Container>
  );
}
