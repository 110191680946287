import React from 'react';
import {Helmet} from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
export default function OrganizasyonSemasi() {
  return (
    <div>
      <Helmet>
            <title>Organizasyon Şeması</title>
            <meta name="description" content="Organizasyon Şeması" />
            <meta name="keywords" content="Organizasyon Şeması" />
        </Helmet>

        <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="announcementsContainer">
              <div className="innerAnnouncements">
                <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
                <span>Organizasyon Şeması</span>
              </div>
              <div className="announcements">
                <div className="container" style={{display:'flex', justifyContent:'center'}}>
                    <img src="/organizasyon_semasi.PNG" alt="" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
