import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet'
export default function BilgiIslemHizmetleri() {

  return (
    <div className="container">
      <Helmet>
            <title>Bilgi İşlem Hizmetleri</title>
            <meta name="description" content="Bilgi İşlem Hizmetleri" />
            <meta name="keywords" content="Bilgi İşlem Hizmetleri" />
        </Helmet>
    <div className="row">
      <div className="col-lg-12">
        <div className="announcementsContainer">
          <div className="innerAnnouncements">
            <FontAwesomeIcon icon={faAnglesRight} style={{ fontSize: "1rem", marginRight: "5px" }} />
            <span>Bilgi İşlem Departmanı Genel Hizmetleri</span>
          </div>
          <div className="announcements">
            <p>Odamızın Bilgi İşlemle ilgili ihtiyaçlarını tespit etmek, bu ihtiyaçlara cevap verebilecek teknoloji ve yeniliklerin takibi, araştırılması en uygun yazılım ve donanımların Odamız sistemine entegrasyonu,
Alınması kararlaştırılan Bilgisayar, Yazıcı, yazılım ve diğer çevre birimleriyle ilgili Teknik Şartname hazırlayarak Muhasebe/Finans Sorumlusu ile ortak çalışma yapmak,
Günlük olarak ortaya çıkan bilgisayar, yazıcı, program, ağ ve kullanıcı hatalarının tespit edilip giderilmesi,
Odamızın internet sayfasını, yönetmek, güncellemek, bakımını yapmak ve kontrol etmek,
Odamız hizmet birimlerinin yapacağı duyurular, güncellemeler ve mevzuatlar konularında web sayfasında yayınlanması gereken dokümaların web sayfasında yayınlanmasını sağlamak,
Odamız üyelerine yapılacak bilgilendirme çalışmalarında kullanılmak üzere üyelerimizin telefon numaralarını Sms sistemine kaydetmek ve gerektiğinde duyuruları yapmak,
Posta , faks ve mail yoluyla gelen Yurt içi ve dışı fuarları takip etmek web sitesinde duyurularını yapmak,
Her yılın sonunda hazırlanan odamız faaliyetleri raporunu düzenlemek </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}
